import React, { useState, useEffect, useContext } from 'react';
import {
  TextField,
  Button,
  CircularProgress,
  InputLabel,
  Select,
  InputAdornment,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core';
import {
  fetchServices,
  createProvider,
  createHomeItem,
  API_BASE,
  fetchCategories,
} from '../../../helpers/api';
import { createSale } from '../../../helpers/api';
import axios from 'axios';

import Dropzone from 'react-dropzone';
import { NotificationContext } from '../../../components/Layout/notificationContext';

// import { Container } from './styles';

function HomeItemCreator(props) {
  const [discount, setDiscount] = useState('');
  const [allCategories, setAllCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [image, setImage] = useState('');
  const [changedPhoto, setChangedPhoto] = useState(false);
  const [allServices, setAllServices] = useState([]);
  const [service, setService] = useState('');
  const [render, setRender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState('');

  const [checkedOnlyImage, setCheckedOnlyImage] = useState(false);

  useEffect(() => {
    async function fetchData() {
      var newServices = [];
      try {
        newServices = await fetchServices();
        setAllServices(newServices);
        let newCategories = await fetchCategories();
        setAllCategories(newCategories);
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
    console.log(props.initialState);
    if (props.initialState) populateCreator(props.initialState);
  }, []);

  const notification = useContext(NotificationContext);

  const handleItemCreation = async () => {
    setLoading(true);
    if (!checkValid) return;
    try {
      const response = await createHomeItem(
        discount,
        service,
        image,
        price,
        categories,
        checkedOnlyImage
      );
      resetStateOnSucess('Added new home item');
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const populateCreator = async (initialState) => {
    const { discount, service, id } = initialState;
    let [parsedDiscount] = discount.split('%');
    setDiscount(parsedDiscount);
    console.log(service);
    setService(service);
    const { data: image } = await axios.get(`${API_BASE}/home/image/${id}`);
    setImage(image);
  };

  const handleUpdateHomeItem = async () => {
    setLoading(true);
    if (!checkValid) return;
    try {
      const updated = await createHomeItem(
        discount,
        service,
        changedPhoto ? image : null,
        price,
        true,

        props.initialState.id
      );
      resetStateOnSucess('Updated home item');
      console.log(updated);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const resetStateOnSucess = (message) => {
    notification.current.sendToast('SUCCESS', message);
    props.onCreateNew();
    setService('');
    setDiscount('');
    setImage('');
    setPrice('');
    setLoading(false);
  };

  const checkValid = () => {
    return discount > 0 && discount <= 100 && image != '';
  };

  const handleImageSelection = (files) => {
    console.log(files[0]);
    setImage(files[0]);
    setChangedPhoto(true);
  };

  const uploadZoneMessage = (isDragActive, isDragReject) => {
    const DEFAULT_PADDING = 25;
    return (
      <>
        {isDragActive && !isDragReject && (
          <Typography
            style={{
              color: '#78e5d5',
              textAlign: 'center',
              padding: DEFAULT_PADDING,
            }}
          >
            Release the file to upload it
          </Typography>
        )}
        {isDragReject && (
          <Typography
            style={{
              color: '#e57878',
              textAlign: 'center',
              padding: DEFAULT_PADDING,
            }}
          >
            Your file isn't compatible
          </Typography>
        )}
        {!isDragActive && !isDragReject && !image && (
          <Typography
            style={{
              color: '#999',
              textAlign: 'center',
              padding: DEFAULT_PADDING,
            }}
          >
            Drag your image here or click to select
          </Typography>
        )}
        {image && (
          <div className="preview-container">
            <img
              className="preview-image"
              src={
                props.initialState && !changedPhoto
                  ? `${API_BASE}/home/image/${props.initialState.id}`
                  : URL.createObjectURL(image)
              }
            />
            <Typography
              style={{
                color: '#999',
                textAlign: 'center',
                padding: DEFAULT_PADDING,
              }}
            >
              {image.name}
            </Typography>
          </div>
        )}
      </>
    );
  };

  return (
    <React.Fragment>
      <div className="fill-width">
        {!checkedOnlyImage && (
          <TextField
            value={discount}
            id="standard-basic"
            label="Discount Amount"
            onChange={(e) => setDiscount(e.target.value)}
            margin="normal"
            helperText={
              !(discount > 0 && discount <= 100) || price
                ? 'discount should be between 0 and 100% (optional), price will prevail'
                : ''
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            placeholder="e.g. 30"
            type="number"
            style={{ marginRight: 10 }}
          />
        )}
        <FormControl component="fieldset">
          <FormGroup
            aria-label="position"
            row
            style={{ marginLeft: 5, marginTop: 20 }}
          >
            <FormControlLabel
              value="CheckedFreeService"
              control={
                <Switch
                  checked={checkedOnlyImage}
                  onChange={(event) =>
                    setCheckedOnlyImage(event.target.checked)
                  }
                  name="checkedOnlyImage"
                  color="primary"
                />
              }
              label="Is only image (to show a coupom)?"
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
        {/* <TextField
          value={price}
          id="standard-basic"
          label="Price"
          onChange={(e) => setPrice(e.target.value)}
          margin="normal"
          helperText={
            !(
              price > 0 &&
              service &&
              allServices.filter((item) => item.id == service)[0] &&
              price < allServices.filter((item) => item.id == service)[0].price
            )
              ? "price should be less than the product's price and more than 0"
              : ''
          }
          InputProps={{
            startAdornment: <InputAdornment position="end">£</InputAdornment>,
          }}
          placeholder="e.g. 30"
          type="number"
          style={{ marginRight: 10 }}
        /> */}

        <br />
        {!checkedOnlyImage && (
          <div>
            <FormControl margin="normal">
              <InputLabel shrink={service != ''} htmlFor="age-native-simple">
                Service
              </InputLabel>
              <Select
                native
                value={service}
                onChange={(e) => {
                  console.log(e.target.value);
                  return setService(e.target.value);
                }}
                inputProps={{
                  name: 'age',
                  id: 'age-native-simple',
                }}
                fullWidth
              >
                <option value=""></option>
                {allServices.map((serviceOptions) => {
                  return (
                    <option key={serviceOptions.id} value={serviceOptions.id}>
                      {serviceOptions.name}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        )}
        {!checkedOnlyImage && (
          <Button
            color="primary"
            style={{ marginTop: 20 }}
            variant="contained"
            margin="normal"
            onClick={() => setCategories([...categories, ''])}
          >
            Add category
          </Button>
        )}
        {!checkedOnlyImage &&
          categories.map((category, index) => (
            <div>
              <FormControl margin="normal">
                <InputLabel shrink={category != ''} htmlFor="age-native-simple">
                  Category {index + 1}
                </InputLabel>
                <Select
                  native
                  value={category}
                  onChange={(e) => {
                    console.log(e.target.value);
                    return setCategories(
                      categories.map((item, catsIndex) =>
                        catsIndex == index ? e.target.value : item
                      )
                    );
                  }}
                  inputProps={{
                    name: 'age',
                    id: 'age-native-simple',
                  }}
                  fullWidth
                >
                  <option value=""></option>
                  {allCategories.map((categoryOptions) => {
                    let arrCopy = categories.slice();
                    arrCopy.splice(index, 1);
                    if (!arrCopy.includes(categoryOptions.id))
                      return (
                        <option
                          key={categoryOptions.id}
                          value={categoryOptions.id}
                        >
                          {categoryOptions.name}
                        </option>
                      );
                  })}
                </Select>
              </FormControl>
            </div>
          ))}
        <InputLabel style={{ marginTop: 20 }} htmlFor="age-native-simple">
          Image
        </InputLabel>
        <Dropzone accept="image/*" onDropAccepted={handleImageSelection}>
          {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
            <div
              {...getRootProps()}
              className={`file-input-container ${
                isDragActive && 'drag-active'
              } ${isDragReject && 'drag-reject'}`}
            >
              <input {...getInputProps()} />
              {uploadZoneMessage(isDragActive, isDragReject)}
            </div>
          )}
        </Dropzone>
        {loading ? (
          <CircularProgress size={26} />
        ) : (
          <Button
            color="primary"
            style={{ marginTop: 20 }}
            variant="contained"
            margin="normal"
            fullWidth
            onClick={() =>
              props.initialState ? handleUpdateHomeItem() : handleItemCreation()
            }
            disabled={
              loading ||
              (((service == '' && categories[0] == '') ||
                !((discount > 0 && discount <= 100) || !discount) ||
                image == '') &&
                (!checkedOnlyImage || image == ''))
            }
          >
            {props.initialState ? 'Update' : 'Create'}
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}

export default HomeItemCreator;
