import React, { useState, useEffect } from 'react';
import { Drawer, IconButton, List } from '@material-ui/core';
import {
  Home as HomeIcon,
  //NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  People as ProvidersIcon,
  CalendarToday as CalendarIcon,
  List as CategoriesIcon,
  RoomServiceTwoTone as ServicesIcon,
  Schedule as ShiftIcon,
  EventAvailable as AppointmentsIcon,
  AttachMoney as SalesIcon,
  NotificationsActive as NotificationsIcon,
  LocalLibrary as TeacherIcon,
  AccountBalance as AccountingIcon,
} from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

// styles
import useStyles from './styles';

// components
import SidebarLink from './components/SidebarLink/SidebarLink';
import Dot from './components/Dot';

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../helpers/LayoutContext';
import { useUserState } from '../../helpers/UserContext';

const structure = [
  // {
  //   id: 0,
  //   label: 'Dashboard',
  //   link: '/app/dashboard',
  //   icon: <HomeIcon />,
  //   role: 2,
  // },
  {
    id: 1,
    label: 'Categories',
    link: '/app/categories',
    icon: <CategoriesIcon />,
    role: 2,
  },
  {
    id: 2,
    label: 'Services',
    link: '/app/tables',
    icon: <ServicesIcon />,
    role: 2,
  },
  {
    id: 3,
    label: 'Providers',
    link: '/app/providers',
    icon: <ProvidersIcon />,
    role: 2,
  },
  {
    id: 4,
    label: 'Staff Shifts',
    link: '/app/shift',
    icon: <ShiftIcon />,
    role: 2,
  },
  {
    id: 5,
    label: 'Calendar',
    link: '/app/calendar',
    icon: <CalendarIcon />,
    role: 2,
  },

  // {
  //   id: 4,
  //   label: 'UI Elements',
  //   link: '/app/ui',
  //   icon: <UIElementsIcon />,
  //   children: [
  //     { label: 'Icons', link: '/app/ui/icons' },
  //     { label: 'Charts', link: '/app/ui/charts' },
  //     { label: 'Maps', link: '/app/ui/maps' },
  //   ],
  // },
  {
    id: 6,
    label: 'Appointments',
    link: '/app/appointments',
    icon: <AppointmentsIcon />,
    role: 2,
  },
  {
    id: 7,
    label: 'Sales',
    link: '/app/sales',
    icon: <SalesIcon />,
    role: 2,
  },
  {
    id: 8,
    label: 'Notifications',
    link: '/app/notifications',
    icon: <NotificationsIcon />,
    role: 2,
  },
  {
    id: 9,
    label: 'Provider',
    link: '/app/teacher',
    icon: <TeacherIcon />,
    role: 1,
  },
  {
    id: 10,
    label: 'Home Item',
    link: '/app/homeItem',
    icon: <HomeIcon />,
    role: 2,
  },
  {
    id: 11,
    label: 'Accounting',
    link: '/app/accounting',
    icon: <AccountingIcon />,
    role: 2,
  },
  // { id: 5, type: 'divider' },
  // { id: 6, type: 'title', label: 'HELP' },
  // { id: 7, label: 'Library', link: '', icon: <LibraryIcon /> },
  // { id: 8, label: 'Support', link: '', icon: <SupportIcon /> },
  // { id: 9, label: 'FAQ', link: '', icon: <FAQIcon /> },
  // { id: 10, type: 'divider' },
  // { id: 11, type: 'title', label: 'PROJECTS' },
  // {
  //   id: 12,
  //   label: 'My recent',
  //   link: '',
  //   icon: <Dot size="small" color="warning" />,
  // },
  // {
  //   id: 13,
  //   label: 'Starred',
  //   link: '',
  //   icon: <Dot size="small" color="primary" />,
  // },
  // {
  //   id: 14,
  //   label: 'Background',
  //   link: '',
  //   icon: <Dot size="small" color="secondary" />,
  // },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  const { user } = useUserState();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((link) => {
          if (!link.role || user.role === link.role)
            return (
              <SidebarLink
                key={link.id}
                location={location}
                isSidebarOpened={isSidebarOpened}
                {...link}
              />
            );
        })}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
