import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';

import Themes from './themes';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { LayoutProvider } from './helpers/LayoutContext';
import { UserProvider } from './helpers/UserContext';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

ReactDOM.render(
  <LayoutProvider>
    <UserProvider>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeProvider theme={Themes.default}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </UserProvider>
  </LayoutProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
