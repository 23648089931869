import React, { useEffect, useState } from 'react';
import { API_BASE, fetchAppointments } from '../../../helpers/api';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
import { Button } from '../../../components/Wrappers/Wrappers';
import ProvidersTimeTable from '../../../components/Table/ProviderTimeTable';
import Axios from 'axios';
import { useUserState } from '../../../helpers/UserContext';

// import { Container } from './styles';

function ProviderAppointmentLister(props) {
  const [appointments, setAppointments] = useState([]);

  const { user } = useUserState();

  useEffect(() => {
    getAppointments();
  }, []);

  async function getAppointments() {
    try {
      //const rawAppointments = await fetchAppointments();
      console.log(user);
      const rawAppointments = await Axios.get(
        `${API_BASE}/appointment/list/byProvider/${user.provider}`
      );
      setAppointments(parseAppointments(rawAppointments.data));
      //console.log(raw);
    } catch (err) {
      console.log(err);
    }
  }

  const setDetail = (id) => {
    props.openDetail(id);
  };

  function parseAppointments(appointments) {
    console.log(appointments);
    var parsedAppointments = [];
    for (var i = 0; i < appointments.length; ++i) {
      const item = appointments[i];
      parsedAppointments.push([
        moment(item.slot_id.slot_date).format('dddd[,] MMMM Do[,] YYYY'),
        item.service ? item.service.name : '',
        item.name,
        item.service ? item.service.price : '',
        item.paid ? 'Paid' : 'Pending',
        item._id,
      ]);
    }
    return parsedAppointments;
  }

  const states = {
    paid: 'success',
    pending: 'warning',
  };

  const statusCollumn = {
    name: 'Status',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => (
        <Button
          color={states[value.toLowerCase()]}
          size="small"
          className="px-2"
          variant="contained"
        >
          {value}
        </Button>
      ),
    },
  };

  return (
    <MUIDataTable
      title="Appointments List"
      data={appointments}
      columns={['Date', 'Service', 'Name', 'Price £', statusCollumn]}
      options={{
        filterType: 'dropdown',
        onRowClick: (_, index) =>
          setDetail(appointments[index.dataIndex][appointments[0].length - 1]),
        // onRowsDelete: (item) => handleCategoryDelete(item.data),
      }}
    />
  );
}

export default ProviderAppointmentLister;
