import React, { useState } from 'react';
import { TextField, Button, CircularProgress } from '@material-ui/core';
import { createCategory } from '../../helpers/api';

// import { Container } from './styles';

function CategoryCreator({ onCreateNew, ...props }) {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCategoryCreation = async () => {
    setLoading(true);
    try {
      const response = await createCategory(name);
      console.log(response);
      onCreateNew();
      setLoading(false);
      setName('');
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="align-center fill-width">
        <TextField
          value={name}
          id="standard-basic"
          label="Name"
          onChange={(e) => setName(e.target.value)}
          margin="normal"
          placeholder="e.g. Laser Upperbody"
          type="text"
          fullWidth
        />
        {loading ? (
          <CircularProgress size={26} />
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={async () => await handleCategoryCreation()}
            disabled={loading || name.length < 3}
          >
            Create
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}

export default CategoryCreator;
