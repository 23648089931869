import React, { useState, useContext } from 'react';
import { Button, CircularProgress, Typography } from '@material-ui/core';

import { importAppointmentsTable } from '../../../helpers/api';
import { NotificationContext } from '../../../components/Layout/notificationContext';
import Dropzone from 'react-dropzone';

function AppointmentImporter({ onCreateNew, ...props }) {
  const [loading, setLoading] = useState(false);
  const [excelTable, setExcelTable] = useState('');

  const notification = useContext(NotificationContext);

  const importAppointments = async () => {
    setLoading(true);
    try {
      const response = await importAppointmentsTable({ excelTable });

      notification.current.sendToast(
        'SUCCESS',
        'Appointments imported successfully'
      );

      console.log(response);

      setExcelTable('');
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleTableSelection = (files) => {
    setExcelTable(files[0]);
  };

  const uploadZoneMessage = (isDragActive, isDragReject) => {
    const DEFAULT_PADDING = 25;

    const dropZoneProps = () => {
      if (isDragActive && !isDragReject)
        return { color: '#78e5d5', text: 'Release the file to upload it' };
      else if (isDragReject)
        return { color: '#e57878', text: "Your file isn't compatible" };
      else
        return {
          color: '#999',
          text: 'Drag your excel table here or click to select',
        };
    };

    return (
      <>
        <Typography
          style={{
            color: dropZoneProps().color,
            textAlign: 'center',
            padding: DEFAULT_PADDING,
          }}
        >
          {dropZoneProps().text}
        </Typography>

        {excelTable && (
          <div className="preview-container">
            {/* <img
              className="preview-image"
              src={URL.createObjectURL(excelTable)}
            /> */}
            <Typography
              style={{
                color: '#999',
                textAlign: 'center',
                padding: DEFAULT_PADDING,
              }}
            >
              {excelTable.name}
            </Typography>
          </div>
        )}
      </>
    );
  };

  return (
    <React.Fragment>
      <div className="fill-width">
        <Dropzone
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onDropAccepted={handleTableSelection}
        >
          {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
            <div
              {...getRootProps()}
              className={`file-input-container ${
                isDragActive && 'drag-active'
              } ${isDragReject && 'drag-reject'}`}
            >
              <input {...getInputProps()} />
              {uploadZoneMessage(isDragActive, isDragReject)}
            </div>
          )}
        </Dropzone>
        {loading ? (
          <CircularProgress size={26} />
        ) : (
          <Button
            color="primary"
            variant="contained"
            margin="normal"
            fullWidth
            onClick={async () => await importAppointments()}
            style={{ marginTop: 10 }}
            disabled={loading}
          >
            Import appointments
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}

export default AppointmentImporter;
