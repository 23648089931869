import { CircularProgress, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
// components
import PageTitle from '../../../components/PageTitle/PageTitle';
import ProvidersTimeTable from '../../../components/Table/ProviderTimeTable';
import Widget from '../../../components/Widget/Widget';
import { fetchProviders } from '../../../helpers/api';
import HolidayCreator from './HolidayCreator';
import HolidayLister from './HolidayLister';

const useStyles = makeStyles({
  list: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  spinnerContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default function TimesTable() {
  const classes = useStyles();
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [providersSchedule, setProvidersSchedule] = useState([]);
  const [open, setOpen] = useState(false);
  // useEffect(() => {
  //   getProviders();
  // }, []);

  // async function getProviders() {
  //   try {
  //     const rawProviders = await fetchProviders();
  //     var parsedProviders = [];
  //     for (var i = 0; i < rawProviders.length; ++i) {
  //       const item = rawProviders[i];
  //       parsedProviders.push([item.name, item.services, item.days, item._id]);
  //     }
  //     setProviders(parsedProviders);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  // function handleCategoryDelete(index) {
  //   var deletedIndexes = [];
  //   index.forEach((item) => {
  //     deletedIndexes.push(item.dataIndex);
  //   });
  //   console.log(deletedIndexes);
  // }

  useEffect(() => {
    getProvidersSchedule();
  }, []);

  const getProvidersSchedule = async () => {
    setLoading(true);
    try {
      const rawProviders = await fetchProviders();
      var parsedProviders = [];
      for (var i = 0; i < rawProviders.length; ++i) {
        const item = rawProviders[i];
        parsedProviders.push({
          name: item.name,
          _id: item._id,
          data: item.days,
        });
      }
      setProvidersSchedule(parsedProviders);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <>
      <PageTitle title="Staff Hours Manager" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {loading ? (
            <Paper
              style={{
                display: 'flex',
                flex: 1,
                height: 550,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className="spinnerContainer"
            >
              <CircularProgress />
            </Paper>
          ) : (
            <Widget title="Modify working hours" upperTitle disableWidgetMenu>
              <React.Fragment>
                <ProvidersTimeTable
                  data={providersSchedule}
                  refreshSchedule={() => getProvidersSchedule()}
                />
              </React.Fragment>
            </Widget>
          )}
        </Grid>
        <Grid item xs={12}>
          <Widget title="Schedule a new holiday" upperTitle disableWidgetMenu>
            <HolidayCreator />
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget title="Holidays" upperTitle disableWidgetMenu>
            <HolidayLister />
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}

function slotTimeToString(slot) {
  const hour = Math.floor((slot * 15) / 60);
  const minutes = slot * 15 - Math.floor((slot * 15) / 60) * 60;
  const timeString =
    `${hour}`.padStart(2, 0) + ':' + `${minutes}`.padStart(2, 0);
  return timeString;
}
