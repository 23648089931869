import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

// components
import Layout from './components/Layout/Layout';
import PrivateRoute from './components/RouteTypes/PrivateRoute';
import PublicRoute from './components/RouteTypes/PublicRoute';

// pages
import Error from './pages/error/Error';
import Login from './pages/auth/Login';

import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import { useUserDispatch } from './helpers/UserContext';
import { API_BASE } from './helpers/api';
import LoadingView from './helpers/LoadingView';
import axios from 'axios';

function App() {
  const [loading, setLoading] = useState(false);
  const dispatch = useUserDispatch();

  useEffect(() => {
    checkAuthentication();
    if (process.env.SHORT_COMPANY_NAME)
      document.title = process.env.SHORT_COMPANY_NAME + 'Dashboard';
  }, []);

  const checkAuthentication = async () => {
    setLoading(true);

    if (!localStorage.getItem('id_token')) {
      setLoading(false);
      return;
    }
    const token = localStorage.getItem('id_token');
    try {
      const signIn = await axios.post(`${API_BASE}/auth/signinbytoken`, {
        token,
      });
      if (signIn.data.user && signIn.data.user.role > 0)
        dispatch({ type: 'LOGIN_SUCCESS', payload: signIn.data.user });

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      {loading ? (
        <LoadingView />
      ) : (
        <HashRouter>
          <Switch>
            <Route
              exact
              path="/"
              render={() => <Redirect to="/app/dashboard" />}
            />
            <Route
              exact
              path="/app"
              render={() => <Redirect to="/app/dashboard" />}
            />
            <PrivateRoute path="/app" component={Layout} />
            <PublicRoute path="/login" component={Login} />
            <PublicRoute
              exact
              path="/forgot_password"
              component={ForgotPassword}
            />
            <PublicRoute
              path="/reset_password/:token"
              component={ResetPassword}
            />
            <Route component={Error} />
          </Switch>
        </HashRouter>
      )}
    </React.Fragment>
  );
}

export default App;
