import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  CircularProgress,
  InputLabel,
  Select,
  InputAdornment,
} from '@material-ui/core';
import { createService, fetchCategories } from '../../helpers/api';
import { makeStyles } from '@material-ui/styles';

// import { Container } from './styles';

const useStyles = makeStyles({
  distribuiter: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
});

function ServiceCreator({ onCreateNew, ...props }) {
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [timeInterval, setTimeInterval] = useState(null);
  const [price, setPrice] = useState(null);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    async function getCategories() {
      var newCategories = [];
      try {
        newCategories = await fetchCategories();
        setCategories(newCategories);
        console.log(newCategories);
      } catch (err) {
        console.log(err);
      }
    }
    getCategories();
    //setCategories(newCategories);
  }, []);

  const handleServiceCreation = async () => {
    setLoading(true);
    try {
      const response = await createService(
        name,
        category,
        timeInterval / 15,
        price
      );
      console.log(response);
      onCreateNew();
      setTimeInterval('');
      setPrice('');
      setName('');
      setCategory('');
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="fill-width">
        <TextField
          value={name}
          id="standard-basic"
          label="Name"
          onChange={(e) => setName(e.target.value)}
          margin="normal"
          placeholder="e.g. Laser Hollywood arm"
          type="text"
          fullWidth
        />
        <div className={classes.distribuiter}>
          <TextField
            value={price}
            id="standard-basic"
            label="Price"
            onChange={(e) => setPrice(e.target.value)}
            margin="normal"
            placeholder="e.g. 40"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">£</InputAdornment>
              ),
            }}
          />
          <TextField
            value={timeInterval}
            error={timeInterval % 15 != 0}
            helperText={
              timeInterval % 15 != 0 ? 'Time should be a multiple of 15' : ''
            }
            id="standard-basic"
            label="Duration"
            onChange={(e) => setTimeInterval(e.target.value)}
            margin="normal"
            placeholder="The duration in minutes 15, 30, 45..."
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">min</InputAdornment>,
            }}
            style={{ flex: 1, marginLeft: 20, minWidth: 100 }}
          />
        </div>
        <InputLabel htmlFor="age-native-simple">Category</InputLabel>
        <Select
          native
          value={category}
          onChange={(e) => {
            console.log(e.target.value);
            setCategory(e.target.value);
          }}
          inputProps={{
            name: 'age',
            id: 'age-native-simple',
          }}
          fullWidth
        >
          <option value=""></option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </Select>
        {loading ? (
          <CircularProgress size={26} />
        ) : (
          <Button
            color="primary"
            variant="contained"
            margin="normal"
            fullWidth
            onClick={async () => await handleServiceCreation()}
            style={{ marginTop: 10 }}
            disabled={
              loading ||
              name.length < 3 ||
              category === '' ||
              !price ||
              !timeInterval ||
              timeInterval % 15 != 0
            }
          >
            Create
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}

export default ServiceCreator;
