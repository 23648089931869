import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
} from '@material-ui/core';

// components
import PageTitle from '../../../components/PageTitle/PageTitle';
import Widget from '../../../components/Widget/Widget';
import AppointmentDetail from '../appointmentDetail/AppointmentDetail';
import AppointmentLister from './AppointmentLister';
import CostumerLister from './CostumerLister';
import AppointmentCreator from './appointmentCreator';
import { useRef } from 'react';
import AppointmentImporter from './AppointmentImporter';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(0),
  },
}));

export default function Appointments(props) {
  const [detailOpened, setDetailOpened] = useState(false);
  const [appointmentToLoad, setAppointmentToLoad] = useState('');
  const [appointmentCreatorState, setAppointmentCreatorState] = useState({
    isEditing: false,
    appointmentToEdit: {},
  });
  const [appointmentToReturn, setAppointmentToReturn] = useState({});

  const appointmentDetailRef = useRef(null);
  const listerRef = useRef(null);

  const classes = useStyles();

  useEffect(() => {
    if (
      props.location.scheduleReturnState &&
      Object.keys(props.location.scheduleReturnState).length
    )
      setTimeout(
        () => handleScheduleReturn(props.location.scheduleReturnState),
        2000
      );

    if (props.location.service) openDetail(props.location.service);
  }, []);

  const handleAppointmentToEditSelected = (appointment) => {
    setAppointmentCreatorState({
      isEditing: true,
      appointmentToEdit: appointment,
    });
  };

  const closeAppointmentEditor = () => {
    setAppointmentCreatorState({
      isEditing: false,
      appointmentToEdit: {},
    });
  };

  const openDetail = (appointmentId) => {
    setAppointmentToLoad(appointmentId);
    setDetailOpened(true);
    scrollToAppointmentDetail();
  };

  const closeDetail = () => {
    setDetailOpened(false);
  };

  const scrollToAppointmentDetail = () => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };

  const handleScheduleReturn = (appointment) => {
    setAppointmentToReturn(appointment);
    window.scrollTo({
      left: 0,
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  const handleAppointmentsReload = () => {
    console.log(listerRef.current);
    if (listerRef.current) listerRef.current.reloadAppointments();
  };

  return (
    <>
      <PageTitle title="Appointments Manager" />
      <div ref={appointmentDetailRef} />
      <Grid container spacing={4}>
        {detailOpened && (
          <Grid item xs={12}>
            <Widget title="Appointment Details" upperTitle disableWidgetMenu>
              <AppointmentDetail
                openDetail={openDetail}
                closeDetail={closeDetail}
                appointmentToLoad={appointmentToLoad}
                handleEditAppointment={{
                  select: handleAppointmentToEditSelected,
                  close: closeAppointmentEditor,
                }}
                scheduleReturn={handleScheduleReturn}
              />
            </Widget>
          </Grid>
        )}
        <Grid item xs={12}>
          <Widget title="Appointments" upperTitle disableWidgetMenu>
            <AppointmentLister openDetail={openDetail} reference={listerRef} />
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget title="Costumers" upperTitle disableWidgetMenu>
            <CostumerLister
              openDetail={openDetail}
              populateCreate={handleScheduleReturn}
            />
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget title="Create Appointment" upperTitle disableWidgetMenu>
            <AppointmentCreator
              initialState={appointmentToReturn}
              reloadAppointments={handleAppointmentsReload}
            />
          </Widget>
        </Grid>
        <Dialog
          open={appointmentCreatorState.isEditing}
          onClose={closeAppointmentEditor}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Edit Appointment</DialogTitle>
          <div className={classes.dialog}>
            <DialogContentText>
              Edit the appointment attributes here:
            </DialogContentText>
            <AppointmentCreator
              isEditing={appointmentCreatorState.isEditing}
              appointmentToEdit={appointmentCreatorState.appointmentToEdit}
            />
          </div>
        </Dialog>
      </Grid>
    </>
  );
}
