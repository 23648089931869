import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';

// components
import PageTitle from '../../../components/PageTitle/PageTitle';
import Widget from '../../../components/Widget/Widget';
import { deleteService, fetchSales, deleteSale } from '../../../helpers/api';
import ServiceCreator from '../ServiceCreator';
import { ToastContainer } from 'react-toastify';
import SalesCreator from './SalesCreator';
import { NotificationContext } from '../../../components/Layout/notificationContext';

export default function Sales() {
  const [sales, setSales] = useState([]);

  useEffect(() => {
    getSales();
  }, []);

  async function getSales() {
    try {
      const rawSales = await fetchSales();
      var parsedSales = [];
      for (var i = 0; i < rawSales.length; ++i) {
        const item = rawSales[i];
        parsedSales.push([
          item.amount.mode === 'Percentage'
            ? `${item.amount.value * 100}%`
            : `£${item.amount.value}`,
          item.code,
          item.service ? item.service.map((item) => item.name).join(', ') : '',
          item.isUniqueUse ? 'Yes' : 'No',
          item.allCart ? 'Yes' : 'No',
          item._id,
        ]); // Add case it's an array of services, currently it only supports a single service
      }
      console.log(parsedSales);
      setSales(parsedSales);
    } catch (err) {
      console.log(err);
    }
  }

  const notification = useContext(NotificationContext);

  async function handleSaleDelete(index) {
    var deletedIndexes = [];
    index.forEach((item) => {
      deletedIndexes.push(item.dataIndex);
    });
    for (var i = 0; i < deletedIndexes.length; ++i) {
      try {
        const response = await deleteSale(
          sales[deletedIndexes[i]][sales[0].length - 1]
        );
        notification.current.sendToast('SUCCESS', 'Sale deleted successfully');
        console.log(response);
      } catch (err) {
        console.log(err);
      }
    }
  }

  return (
    <>
      <PageTitle title="Sales Manager" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title="Add new sale" upperTitle disableWidgetMenu>
            <SalesCreator onCreateNew={() => getSales()} />
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <MUIDataTable
            title="Sales List"
            data={sales}
            columns={[
              'Amount',
              'Code',
              'Services',
              'Is unique use',
              'Applies to any service',
            ]}
            options={{
              filterType: 'checkbox',
              onRowsDelete: (item) => handleSaleDelete(item.data),
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
