import MUIDataTable from 'mui-datatables';
import React, { useContext, useEffect, useState } from 'react';
import { NotificationContext } from '../../../components/Layout/notificationContext';
import { deleteHoliday, fetchHolidays } from '../../../helpers/api';

export default function HolidayLister() {
  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    getHolidays();
  }, []);

  async function getHolidays() {
    try {
      const rawHolidays = await fetchHolidays();

      console.log(rawHolidays);

      let parsedHolidays = [];

      for (var i = 0; i < rawHolidays.length; ++i) {
        const item = rawHolidays[i];
        parsedHolidays.push([
          new Date(item.date_start).toLocaleDateString(),
          new Date(item.date_end).toLocaleDateString(),
          item.allProv ? '' : item.providers.map((item) => item.name).join(','),
          item.allProv ? 'Yes' : 'No',
          item._id,
        ]);
      }

      setHolidays(parsedHolidays);
    } catch (err) {
      console.log(err);
    }
  }

  const notification = useContext(NotificationContext);

  async function handleHolidayDelete(index) {
    var deletedIndexes = [];
    index.forEach((item) => {
      deletedIndexes.push(item.dataIndex);
    });
    for (var i = 0; i < deletedIndexes.length; ++i) {
      try {
        const response = await deleteHoliday(
          holidays[deletedIndexes[i]][holidays[0].length - 1]
        );
        notification.current.sendToast(
          'SUCCESS',
          'Holiday deleted successfully'
        );
        console.log(response);
      } catch (err) {
        console.log(err);
      }
    }
  }

  return (
    <>
      {/* <PageTitle title="Holidays Manager" /> */}
      {/* <Grid container spacing={4}> */}
      {/* <Grid item xs={12}>
          <Widget title="Add new holiday" upperTitle disableWidgetMenu>
            <HolidaysCreator onCreateNew={() => getHolidays()} />
          </Widget>
        </Grid> */}
      {/* <Grid item xs={12}> */}
      <MUIDataTable
        title="Holidays List"
        data={holidays}
        columns={['Start Date', 'End Date', 'Providers', 'All providers']}
        options={{
          filterType: 'checkbox',
          onRowsDelete: (item) => handleHolidayDelete(item.data),
        }}
      />
      {/* </Grid> */}
      {/* </Grid> */}
    </>
  );
}
