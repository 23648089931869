import React, { useState, useEffect, useContext } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  TextField,
  DialogActions,
} from '@material-ui/core';
import MUIDataTable from 'mui-datatables';

import { Close as CloseIcon } from '@material-ui/icons';

// components
import PageTitle from '../../../components/PageTitle/PageTitle';
import Widget from '../../../components/Widget/Widget';
import Table from '../../../components/Table/CategoriesTable';
import {
  fetchCategories,
  deleteCategory,
  API_BASE,
} from '../../../helpers/api';
import CategoryCreator from '../CategoryCreator';
import { ToastContainer } from 'react-toastify';
import notificationHandler from '../../../helpers/notificationHandler';

import { toast } from 'react-toastify';

// styles
import 'react-toastify/dist/ReactToastify.css';

// components
import Notification from '../../../components/Notification/Notification';
import { useToastStyles } from '../toastStyles/toastStyles';
import Axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import { NotificationContext } from '../../../components/Layout/notificationContext';

// data
// import mock from '../dashboard/mock';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(3),
  },
}));

export default function Categories() {
  const [categories, setCategories] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const classes = useToastStyles();

  const notification = useContext(NotificationContext);

  const dialogStyles = useStyles();

  useEffect(() => {
    getCategories();
  }, []);

  async function getCategories() {
    try {
      const rawCategories = await fetchCategories();
      var parsedCategories = [];
      for (var i = 0; i < rawCategories.length; ++i) {
        const item = rawCategories[i];
        parsedCategories.push([item.name, item.id]);
      }
      setCategories(parsedCategories);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleCategoryDelete(index) {
    var deletedIndexes = [];
    index.forEach((item) => {
      deletedIndexes.push(item.dataIndex);
    });
    for (var i = 0; i < deletedIndexes.length; ++i) {
      try {
        const response = await deleteCategory(categories[deletedIndexes[i]][1]);
        notification.current.sendToast(
          'SUCCESS',
          'Category Deleted successfully'
        );
        getCategories();
        console.log(response);
      } catch (err) {
        console.log(err);
      }
    }
  }

  const handleEditClose = () => {
    setEditOpen(false);
    setName('');
    setId('');
  };

  const handleEditConfirm = async () => {
    try {
      const response = await Axios.put(`${API_BASE}/category/update/${id}`, {
        name,
      });
      console.log(response);
      handleEditClose();
      getCategories();
    } catch (err) {
      console.log(err);
    }
  };

  const categoryEditor = () => {
    return (
      <Dialog
        open={editOpen}
        onClose={() => handleEditClose()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update category name</DialogTitle>
        <div className={dialogStyles.dialog}>
          <DialogContentText>
            Set category's new name in the field below
          </DialogContentText>
          <TextField
            value={name}
            id="standard-basic"
            label="Name"
            onChange={(e) => setName(e.target.value)}
            margin="normal"
            placeholder="e.g. Laser Upperbody"
            type="text"
            fullWidth
          />
        </div>
        <DialogActions>
          <Button onClick={() => handleEditClose()} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <ToastContainer
        className={classes.toastsContainer}
        closeButton={
          <CloseButton className={classes.notificationCloseButton} />
        }
        closeOnClick={false}
        progressClassName={classes.notificationProgress}
      />
      <PageTitle title="Category Manager" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title="Add new category" upperTitle disableWidgetMenu>
            <CategoryCreator
              onCreateNew={() => {
                notification.current.sendToast('SUCCESS', 'Category Created');
                getCategories();
              }}
            />
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <MUIDataTable
            title="Categories List"
            data={categories}
            columns={['Name']}
            options={{
              filterType: 'checkbox',
              onRowsDelete: (item) => handleCategoryDelete(item.data),
              onRowClick: (_, index) => {
                setId(categories[index.dataIndex][1]);
                setName(categories[index.dataIndex][0]);
                setEditOpen(true);
              },
            }}
          />
        </Grid>
      </Grid>
      {categoryEditor()}
    </>
  );
  // return (
  //   <>
  //     <PageTitle title="Categories" />
  //     <Grid container spacing={4}>
  //       <Grid item xs={12}>
  //         <MUIDataTable
  //           title="Employee List"
  //           data={datatableData}
  //           columns={['Name', 'Company', 'City', 'State']}
  //           options={{
  //             filterType: 'checkbox',
  //           }}
  //         />
  //       </Grid>
  //       <Grid item xs={12}>
  //         <Widget title="Material-UI Table" upperTitle noBodyPadding>
  //           <Table data={mock.table} />
  //         </Widget>
  //       </Grid>
  //     </Grid>
  //   </>
  // );

  function sendNotification(componentProps, options) {
    return toast(
      <Notification
        {...componentProps}
        className={classes.notificationComponent}
      />,
      options
    );
  }

  function handleNotificationCall(notificationType, message) {
    var componentProps;

    //if (notificationType === 'error') return;

    switch (notificationType) {
      case 'info':
        componentProps = {
          type: 'feedback',
          message: `${message}`,
          variant: 'contained',
          color: 'primary',
        };
        break;
      case 'error':
        componentProps = {
          type: 'message',
          message: `${message}`,
          variant: 'contained',
          color: 'secondary',
          // extraButton: 'Resend',
          // extraButtonClick: retryErrorNotification,
        };
        break;
      default:
        componentProps = {
          type: 'shipped',
          message: `${message}`,
          variant: 'contained',
          color: 'success',
        };
    }
    var toastId = sendNotification(componentProps, {
      type: notificationType,
      position: toast.POSITION.TOP_RIGHT,
      progressClassName: classes.progress,
      // onClose: notificationType === 'error' && (() => setErrorToastId(null)),
      className: classes.notification,
    });
    //if (notificationType === 'error') setErrorToastId(toastId);
  }
}

function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}

const mock = {
  tasks: [
    {
      id: 0,
      type: 'Meeting',
      title: 'Meeting with Andrew Piker',
      time: '9:00',
    },
    {
      id: 1,
      type: 'Call',
      title: 'Call with HT Company',
      time: '12:00',
    },
    {
      id: 2,
      type: 'Meeting',
      title: 'Meeting with Zoe Alison',
      time: '14:00',
    },
    {
      id: 3,
      type: 'Interview',
      title: 'Interview with HR',
      time: '15:00',
    },
  ],
  bigStat: [
    {
      product: 'Light Blue',
      total: {
        monthly: 4232,
        weekly: 1465,
        daily: 199,
        percent: { value: 3.7, profit: false },
      },
      color: 'primary',
      registrations: {
        monthly: { value: 830, profit: false },
        weekly: { value: 215, profit: true },
        daily: { value: 33, profit: true },
      },
      bounce: {
        monthly: { value: 4.5, profit: false },
        weekly: { value: 3, profit: true },
        daily: { value: 3.25, profit: true },
      },
    },
    {
      product: 'Sing App',
      total: {
        monthly: 754,
        weekly: 180,
        daily: 27,
        percent: { value: 2.5, profit: true },
      },
      color: 'warning',
      registrations: {
        monthly: { value: 32, profit: true },
        weekly: { value: 8, profit: true },
        daily: { value: 2, profit: false },
      },
      bounce: {
        monthly: { value: 2.5, profit: true },
        weekly: { value: 4, profit: false },
        daily: { value: 4.5, profit: false },
      },
    },
    {
      product: 'RNS',
      total: {
        monthly: 1025,
        weekly: 301,
        daily: 44,
        percent: { value: 3.1, profit: true },
      },
      color: 'secondary',
      registrations: {
        monthly: { value: 230, profit: true },
        weekly: { value: 58, profit: false },
        daily: { value: 15, profit: false },
      },
      bounce: {
        monthly: { value: 21.5, profit: false },
        weekly: { value: 19.35, profit: false },
        daily: { value: 10.1, profit: true },
      },
    },
  ],
  notifications: [
    {
      id: 0,
      icon: 'thumbs-up',
      color: 'primary',
      content:
        'Ken <span className="fw-semi-bold">accepts</span> your invitation',
    },
    {
      id: 1,
      icon: 'file',
      color: 'success',
      content: 'Report from LT Company',
    },
    {
      id: 2,
      icon: 'envelope',
      color: 'danger',
      content: '4 <span className="fw-semi-bold">Private</span> Mails',
    },
    {
      id: 3,
      icon: 'comment',
      color: 'success',
      content: '3 <span className="fw-semi-bold">Comments</span> to your Post',
    },
    {
      id: 4,
      icon: 'cog',
      color: 'light',
      content: 'New <span className="fw-semi-bold">Version</span> of RNS app',
    },
    {
      id: 5,
      icon: 'bell',
      color: 'info',
      content:
        '15 <span className="fw-semi-bold">Notifications</span> from Social Apps',
    },
  ],
  table: [
    {
      id: 0,
      name: 'Mark Otto',
      email: 'ottoto@wxample.com',
      product: 'ON the Road',
      price: '$25 224.2',
      date: '11 May 2017',
      city: 'Otsego',
      status: 'Sent',
    },
    {
      id: 1,
      name: 'Jacob Thornton',
      email: 'thornton@wxample.com',
      product: 'HP Core i7',
      price: '$1 254.2',
      date: '4 Jun 2017',
      city: 'Fivepointville',
      status: 'Sent',
    },
    {
      id: 2,
      name: 'Larry the Bird',
      email: 'bird@wxample.com',
      product: 'Air Pro',
      price: '$1 570.0',
      date: '27 Aug 2017',
      city: 'Leadville North',
      status: 'Pending',
    },
    {
      id: 3,
      name: 'Joseph May',
      email: 'josephmay@wxample.com',
      product: 'Version Control',
      price: '$5 224.5',
      date: '19 Feb 2018',
      city: 'Seaforth',
      status: 'Declined',
    },
    {
      id: 4,
      name: 'Peter Horadnia',
      email: 'horadnia@wxample.com',
      product: "Let's Dance",
      price: '$43 594.7',
      date: '1 Mar 2018',
      city: 'Hanoverton',
      status: 'Sent',
    },
  ],
};
