import React, { useState, useEffect } from 'react';
import { fetchAppointment, API_BASE } from '../../../helpers/api';
import moment from 'moment';
import {
  Paper,
  CircularProgress,
  Divider,
  Button,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useMemo } from 'react';
import Axios from 'axios';
import { useContext } from 'react';
import { NotificationContext } from '../../../components/Layout/notificationContext';

export default function NotificationSender(props) {
  const [headline, setHeadline] = useState('');
  const [body, setBody] = useState('');
  const [loading, setLoading] = useState(false);
  const notification = useContext(NotificationContext);

  const classes = useStyles();

  useMemo(() => {
    //console.log(props.notificationToLoad);
    setHeadline(props.notificationToLoad[0]);
    setBody(props.notificationToLoad[1]);
  }, [props.notificationToLoad]);

  async function handleNotificationDispatch() {
    if (headline.length < 3 || body.length < 3) return;

    try {
      setLoading(true);
      const newNotification = { title: headline, body };
      const response = await Axios.post(
        `${API_BASE}/notification/send`,
        newNotification
      );
      notification.current.sendToast(
        'SUCCESS',
        'Successfully dispatched notification'
      );
      console.log(response);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className="fill-width">
          <TextField
            value={headline}
            id="standard-basic"
            label="Headline"
            //error={!(amount > 0 && amount <= 100)}
            onChange={(e) => setHeadline(e.target.value)}
            margin="normal"
            // helperText={
            //   headline.length < 3
            //     ? 'Headline should be at least 3 characters long'
            //     : ''
            // }
            placeholder="e.g. New Offer Online"
            type="text"
            fullWidth
            style={{ marginRight: 10 }}
          />
          <TextField
            value={body}
            id="standard-basic"
            label="Body"
            multiline={true}
            //error={code.length < 3}
            onChange={(e) => setBody(e.target.value)}
            margin="normal"
            // helperText={
            //   body.length < 3 ? 'Body should be at least 3 characters long' : ''
            // }
            placeholder="e.g. Check the new discounts in laser waxing! Online for limited time!"
            type="text"
            variant="outlined"
            fullWidth
            style={{ marginRight: 10 }}
          />
          {loading ? (
            <CircularProgress size={26} />
          ) : (
            <Button
              color="primary"
              style={{ marginTop: 20 }}
              variant="contained"
              margin="normal"
              fullWidth
              onClick={async () => await handleNotificationDispatch()}
              disabled={loading || headline.length < 3 || body.length === 0}
            >
              Dispatch Notification
            </Button>
          )}
        </div>
        <Button
          style={{ height: 40, width: 40, marginLeft: 20 }}
          onClick={props.closeSender}
          variant="outlined"
        >
          <Close />
        </Button>
      </div>
    </React.Fragment>
  );
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
});
