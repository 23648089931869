import React, { useState, useEffect } from 'react';

import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';

import 'react-big-calendar/lib/sass/styles.scss';

import { API_BASE, fetchAppointments } from '../../../../helpers/api';
import { Grid } from '@material-ui/core';
import axios from 'axios';
import { useUserState } from '../../../../helpers/UserContext';

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment); // or globalizeLocalizer

let allViews = Object.keys(Views).map((k) => Views[k]);

export default function Basic({ openDetail }) {
  const [appointments, setAppointments] = useState([]);

  const { user } = useUserState();

  useEffect(() => {
    getAppointments();
  }, []);

  async function getAppointments() {
    try {
      let rawAppointments;
      if (user.role === 1)
        rawAppointments = await axios.get(
          `${API_BASE}/appointment/list/byProvider/${user.provider}`
        );
      else if (user.role === 2)
        rawAppointments = await axios.get(`${API_BASE}/appointment/list`);
      console.log(rawAppointments.data);
      var parsedAppointments = parseAppointments(rawAppointments.data);
      setAppointments(parsedAppointments);
    } catch (err) {
      console.log(err);
    }
  }

  function parseAppointments(rawAppointments) {
    var parsedAppointments = [];
    for (var j = 0; j < rawAppointments.length; ++j) {
      const item = rawAppointments[j];
      const [year, month, day] = item.slot_id.slot_date.split('-');
      const hourStart = Math.floor(item.slots[0] / 4);
      const minutesStart = (item.slots[0] % 4) * 15;
      const hourEnd = Math.floor(item.slots[1] / 4);
      const minutesEnd = (item.slots[1] % 4) * 15;
      var start = new Date(year, month - 1, day, hourStart, minutesStart, 0);
      var end = new Date(year, month - 1, day, hourEnd, minutesEnd, 0);
      console.log(start);
      if (!item.canceled)
        parsedAppointments.push({
          id: item._id,
          title: `${item.paid ? '' : '-- PENDING -- '}${item.name} – ${
            item.service && item.service.name
          } (service)`,
          start: start,
          end: end,
        });
    }
    return parsedAppointments;
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Calendar
            events={appointments}
            localizer={localizer}
            defaultView={Views.MONTH}
            views={['day', 'work_week', 'week', 'month', 'agenda']}
            style={{ height: 600 }}
            step={30}
            defaultDate={new Date()}
            onSelectEvent={(event) => openDetail(event.id)}
          />
        </Grid>
      </Grid>
    </>
  );
}
