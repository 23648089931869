import classnames from 'classnames';
import React, { useRef } from 'react';
import { Redirect, Switch, withRouter } from 'react-router-dom';
// context
import { useLayoutState } from '../../helpers/LayoutContext';
import Accounting from '../../pages/dashboard/accounting/accounting';
import Appointments from '../../pages/dashboard/appointments/Appointments';
import CalendarView from '../../pages/dashboard/calendar/Calendar';
import Categories from '../../pages/dashboard/categories/Categories';
import HomeItemController from '../../pages/dashboard/Home/HomeController';
import NotificationModels from '../../pages/dashboard/notifications/Notifications';
import Providers from '../../pages/dashboard/providers/Providers';
import TimesTable from '../../pages/dashboard/providers/TimesTable';
import Sales from '../../pages/dashboard/sales/Sales';
import Services from '../../pages/dashboard/services/Services';
import TeacherView from '../../pages/dashboard/teacher/TeacherView';
import StyledToastContainer from '../../pages/dashboard/toastStyles/StyledToastContainer';
// components
import Header from '../Header/Header';
import AdminRoute from '../RouteTypes/AdminRoute';
import TeacherRoute from '../RouteTypes/TeacherRoute';
import Sidebar from '../Sidebar/Sidebar';
import { NotificationContext } from './notificationContext';
// styles
import useStyles from './styles';

function Layout(props) {
  var classes = useStyles();

  const notificationRef = useRef(null);

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <StyledToastContainer ref={notificationRef} />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <NotificationContext.Provider value={notificationRef}>
            <Switch>
              <AdminRoute path="/app/dashboard" component={Dashboard} />
              <AdminRoute path="/app/categories" component={Categories} />
              <AdminRoute path="/app/tables" component={Services} />
              <AdminRoute path="/app/providers" component={Providers} />
              <AdminRoute path="/app/shift" component={TimesTable} />
              <AdminRoute path="/app/calendar" component={CalendarView} />
              <AdminRoute path="/app/appointments" component={Appointments} />
              <AdminRoute path="/app/sales" component={Sales} />
              <AdminRoute path="/app/homeItem" component={HomeItemController} />
              <AdminRoute path="/app/accounting" component={Accounting} />
              <TeacherRoute path="/app/teacher" component={TeacherView} />
              <AdminRoute
                path="/app/notifications"
                component={NotificationModels}
              />
            </Switch>
          </NotificationContext.Provider>
        </div>
      </>
    </div>
  );
}

const Dashboard = () => {
  return (
    <div>
      <Redirect to="/app/categories" />
      <h1>Hello World Dashboard</h1>
    </div>
  );
};

export default withRouter(Layout);
