import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';

// components
import PageTitle from '../../../components/PageTitle/PageTitle';
import Widget from '../../../components/Widget/Widget';
import {
  deleteService,
  fetchNotificationModels,
  deleteSale,
  deleteNotificationModel,
  API_BASE,
} from '../../../helpers/api';
import ServiceCreator from '../ServiceCreator';
import { ToastContainer } from 'react-toastify';
import NotificationModelCreator from './NotificationCreator';
import NotificationSender from './NotificationSender';
import PaymentReminderCreator from './paymentReminderCreator';
import axios from 'axios';
import moment from 'moment';
import { NotificationContext } from '../../../components/Layout/notificationContext';

export default function NotificationModels() {
  const [notificationModels, setNotificationModels] = useState([]);
  const [senderOpened, setSenderOpened] = useState(false);
  const [notificationToLoad, setNotificationToLoad] = useState({});
  const [paymentReminders, setPaymentReminders] = useState([]);

  useEffect(() => {
    getNotificationModels();
    getPaymentReminders();
  }, []);

  const notification = useContext(NotificationContext);

  async function getNotificationModels() {
    try {
      const rawNotificationModels = await fetchNotificationModels();
      var parsedNotificationModels = [];
      for (var i = 0; i < rawNotificationModels.length; ++i) {
        const item = rawNotificationModels[i];
        parsedNotificationModels.push([item.headline, item.body, item._id]);
      }
      console.log(parsedNotificationModels);
      setNotificationModels(parsedNotificationModels);
    } catch (err) {
      console.log(err);
    }
  }

  async function getPaymentReminders() {
    try {
      const { data } = await axios.get(
        `${API_BASE}/notification/paymentReminders`
      );
      setPaymentReminders(
        data.reminders.map((item) => [
          item.email,
          item.telephone,
          moment(item.nextContact).format('DD/MM/YYYY'),
          item.timePeriod,
          item._id,
        ])
      );
      console.log();
    } catch (err) {
      console.log(err);
    }
  }

  async function handleNotificationModelDelete(index) {
    var deletedIndexes = [];
    index.forEach((item) => {
      deletedIndexes.push(item.dataIndex);
    });
    for (var i = 0; i < deletedIndexes.length; ++i) {
      try {
        const response = await deleteNotificationModel(
          notificationModels[deletedIndexes[i]][2]
        );
        notification.current.sendToast(
          'SUCCESS',
          'Notification model deleted sucessfully'
        );
        console.log(response);
      } catch (err) {
        console.log(err);
      }
    }
  }

  async function handlePaymentReminderDelete(index) {
    var deletedIndexes = [];
    index.forEach((item) => {
      deletedIndexes.push(item.dataIndex);
    });
    console.log(deletedIndexes);
    for (var i = 0; i < deletedIndexes.length; ++i) {
      try {
        const response = await axios.delete(
          `${API_BASE}/notification/paymentReminders/${
            paymentReminders[deletedIndexes[i]][4]
          }`
        );
        notification.current.sendToast(
          'SUCCESS',
          'Payment notification deleted sucessfully'
        );
        console.log(response.data);
      } catch (err) {
        console.log(err);
      }
    }
  }

  async function handleNotificationModelClicked(index) {
    console.log(index);
    setNotificationToLoad(notificationModels[index]);
    setSenderOpened(true);
  }

  return (
    <>
      <PageTitle title="Notifications Manager" />
      <Grid container spacing={4}>
        {senderOpened && (
          <Grid item xs={12}>
            <Widget
              title="Notification Dispatcher"
              upperTitle
              disableWidgetMenu
            >
              <NotificationSender
                openSender={() => setSenderOpened(true)}
                closeSender={() => setSenderOpened(false)}
                notificationToLoad={notificationToLoad}
              />
            </Widget>
          </Grid>
        )}
        <Grid item xs={12}>
          <Widget
            title="Add new notification model"
            upperTitle
            disableWidgetMenu
          >
            <NotificationModelCreator
              onCreateNew={() => getNotificationModels()}
            />
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget title="Create Payment Reminders" upperTitle disableWidgetMenu>
            <PaymentReminderCreator />
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <MUIDataTable
            title="Notification Models List"
            data={notificationModels}
            columns={['Headline', 'Body']}
            options={{
              filterType: 'checkbox',
              onRowClick: (item, idx) =>
                handleNotificationModelClicked(idx.dataIndex),
              onRowsDelete: (item) => handleNotificationModelDelete(item.data),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <MUIDataTable
            title="Payment Reminders List"
            data={paymentReminders}
            columns={[
              'Email',
              'Telephone',
              'Next Contact',
              'Days of contact interval',
            ]}
            options={{
              filterType: 'checkbox',
              onRowsDelete: (item) => handlePaymentReminderDelete(item.data),
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
