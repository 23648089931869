import React from 'react';
import { toast, ToastContainer } from 'react-toastify';

import { Close as CloseIcon } from '@material-ui/icons';

// styles
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from '@material-ui/styles';
import tinycolor from 'tinycolor2';

// components
import Notification from '../components/Notification/Notification';

const useStyles = makeStyles((theme) => ({
  layoutContainer: {
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    border: '1px dashed',
    borderColor: theme.palette.primary.main,
    position: 'relative',
  },
  layoutText: {
    color: tinycolor(theme.palette.background.light).darken().toHexString(),
  },
  layoutButtonsRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  layoutButton: {
    backgroundColor: theme.palette.background.light,
    width: 125,
    height: 50,
    outline: 'none',
    border: 'none',
  },
  layoutButtonActive: {
    backgroundColor: tinycolor(theme.palette.background.light)
      .darken()
      .toHexString(),
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.spacing(2),
  },
  notificationCallButton: {
    color: 'white',
    marginBottom: theme.spacing(1),
    textTransform: 'none',
  },
  codeContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  codeComponent: {
    flexGrow: 1,
  },
  notificationItem: {
    marginTop: theme.spacing(2),
  },
  notificationCloseButton: {
    position: 'absolute',
    right: theme.spacing(2),
  },
  toastsContainer: {
    width: 400,
    marginTop: theme.spacing(6),
    right: 0,
  },
  progress: {
    visibility: 'hidden',
  },
  notification: {
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    boxShadow: 'none',
    overflow: 'visible',
  },
  notificationComponent: {
    paddingRight: theme.spacing(4),
  },
}));

export default function NotificationHandler() {
  const classes = useStyles();
  function sendNotification(componentProps, options) {
    return toast(
      <Notification
        {...componentProps}
        className={classes.notificationComponent}
      />,
      options
    );
  }

  return (
    <ToastContainer
      className={classes.toastsContainer}
      closeButton={<CloseButton className={classes.notificationCloseButton} />}
      closeOnClick={false}
      progressClassName={classes.notificationProgress}
    />
  );

  function handleNotificationCall(notificationType) {
    var componentProps;

    //if (notificationType === 'error') return;

    switch (notificationType) {
      case 'info':
        componentProps = {
          type: 'feedback',
          message: 'New user feedback received',
          variant: 'contained',
          color: 'primary',
        };
        break;
      case 'error':
        componentProps = {
          type: 'message',
          message: 'Message was not sent!',
          variant: 'contained',
          color: 'secondary',
          // extraButton: 'Resend',
          // extraButtonClick: retryErrorNotification,
        };
        break;
      default:
        componentProps = {
          type: 'shipped',
          message: 'The item was shipped',
          variant: 'contained',
          color: 'success',
        };
    }

    var toastId = sendNotification(componentProps, {
      type: notificationType,
      position: toast.POSITION.TOP_RIGHT,
      progressClassName: classes.progress,
      // onClose: notificationType === 'error' && (() => setErrorToastId(null)),
      className: classes.notification,
    });

    //if (notificationType === 'error') setErrorToastId(toastId);
  }
}

function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}
