import React, { useState, useEffect } from 'react';

import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';

import 'react-big-calendar/lib/sass/styles.scss';

// components
import PageTitle from '../../../../components/PageTitle/PageTitle';
import Widget from '../../../../components/Widget/Widget';

import { fetchProviders, fetchAppointments } from '../../../../helpers/api';
import { Grid } from '@material-ui/core';

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment); // or globalizeLocalizer

let allViews = Object.keys(Views).map((k) => Views[k]);

export default function ResourceCalendar({ openDetail }) {
  const [providers, setProviders] = useState([]);
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    getProviders();
    getAppointments();
  }, []);

  async function getProviders() {
    try {
      const rawProviders = await fetchProviders();
      var parsedProviders = [];
      for (var i = 0; i < rawProviders.length; ++i) {
        const item = rawProviders[i];
        parsedProviders.push({
          resourceTitle: item.name,
          resourceId: item._id,
        });
      }
      setProviders(parsedProviders);
    } catch (err) {
      console.log(err);
    }
  }

  async function getAppointments() {
    try {
      const rawAppointments = await fetchAppointments();
      var parsedAppointments = parseAppointments(rawAppointments);
      setAppointments(parsedAppointments);
    } catch (err) {
      console.log(err);
    }
  }

  function parseAppointments(rawAppointments) {
    var parsedAppointments = [];
    for (var j = 0; j < rawAppointments.length; ++j) {
      const item = rawAppointments[j];
      const [year, month, day] = item.slot_id.slot_date.split('-');
      const hourStart = Math.floor(item.slots[0] / 4);
      const minutesStart = (item.slots[0] % 4) * 15;
      const hourEnd = Math.floor(item.slots[1] / 4);
      const minutesEnd = (item.slots[1] % 4) * 15;
      var start = new Date(year, month - 1, day, hourStart, minutesStart, 0);
      var end = new Date(year, month - 1, day, hourEnd, minutesEnd, 0);
      //console.log(start);
      if (!item.canceled)
        parsedAppointments.push({
          id: item._id,
          title: `${item.paid ? '' : '-- PENDING -- '}${item.name} – ${
            item.service && item.service.name
          } (service)`, // item.service && item.service.name,
          start: start,
          end: end,
          resourceId: item.provider && item.provider._id,
        });
    }
    return parsedAppointments;
  }

  return (
    <>
      {/* <PageTitle title="Providers Manager" /> */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {/* <Widget title="See providers Agenda" upperTitle disableWidgetMenu> */}
          <Calendar
            events={appointments}
            localizer={localizer}
            defaultView={Views.DAY}
            views={['day', 'work_week', 'week']}
            style={{ height: 600 }}
            step={30}
            defaultDate={new Date()}
            resources={providers}
            resourceIdAccessor="resourceId"
            resourceTitleAccessor="resourceTitle"
            onSelectEvent={(event) => openDetail(event.id)}
          />
          {/* </Widget> */}
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </>
  );
}
