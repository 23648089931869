import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// context
import { useUserState } from '../../helpers/UserContext';

// This route is allowed to be accessed only if the user is not logged in, e. g. login screen,
// as it makes no sense to allow its access to somebody that's already authenticated. It's the oposite of the Private Route
export default function PublicRoute({ component, ...rest }) {
  // global
  var { isAuthenticated, user } = useUserState();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          user.role === 2 ? (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          ) : user.role === 1 ? (
            <Redirect
              to={{
                pathname: '/app/teacher',
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
              }}
            />
          )
        ) : (
          React.createElement(component, props)
        )
      }
    />
  );
}
