import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  CircularProgress,
  InputLabel,
  Select,
  InputAdornment,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Checkbox,
  RadioGroup,
  Radio,
  FormLabel,
} from '@material-ui/core';
import {
  fetchServices,
  createProvider,
  fetchCategories,
} from '../../../helpers/api';
import { createSale } from '../../../helpers/api';
import { NotificationContext } from '../../../components/Layout/notificationContext';
import { useContext } from 'react';

// import { Container } from './styles';

function SalesCreator(props) {
  const [percentageAmount, setPercentageAmount] = useState('');
  const [flatAmount, setFlatAmount] = useState('');
  const [code, setCode] = useState('');
  const [services, setServices] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [checkedFreeService, setCheckedFreeService] = useState(false);
  const [checkedUniqueUse, setCheckedUniqueUse] = useState(false);
  const [checkedAllProducts, setCheckedAllProducts] = useState(false);
  const [typeOfService, setTypeOfService] = useState('Percentage');
  const [freeService, setFreeService] = useState('');
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');
  const [render, setRender] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      var newServices = [];
      try {
        newServices = await fetchServices();
        setAllServices(newServices);
        console.log(newServices);
      } catch (err) {
        console.log(err);
      }
      setAllServices(newServices);
    }
    fetchData();
    getCategories();
  }, []);

  useEffect(() => {
    if (!category) return;

    let filteredServices = allServices.filter(
      (item) => item.rawCategory == category
    );

    filteredServices = filteredServices.concat(
      allServices.filter((item) => services.includes(item.id))
    );

    filteredServices = Array.from(new Set(filteredServices));

    setServices(filteredServices.map((item) => item.id));
  }, [category]);

  const getCategories = async () => {
    try {
      const categories = await fetchCategories();

      setCategories(categories);
    } catch (err) {
      console.log(err);
    }
  };

  const notification = useContext(NotificationContext);

  const handleSaleCreation = async () => {
    setLoading(true);
    if (!checkValid) return;
    try {
      const response = await createSale({
        percentageAmount,
        flatAmount,
        typeOfService,
        allCart: checkedAllProducts,
        isUniqueUse: checkedUniqueUse,
        code,
        service: Array.from(new Set(services)).filter((item) => item != ''),
        freeService: checkedFreeService ? freeService : '',
      });
      notification.current.sendToast('SUCCESS', 'Sale created sucessfully');
      props.onCreateNew();
      setServices([]);
      setCode('');
      //setAmount('');
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const checkValid = () => {
    return percentageAmount > 0 && percentageAmount <= 100 && code.length >= 3;
  };

  const handleAddService = () => {
    const newService = '';
    var servicesO = services;
    servicesO.push(newService);
    console.log(services);
    setServices(servicesO);
    setRender(!render);
  };

  return (
    <React.Fragment>
      <FormControl component="fieldset">
        <FormLabel component="legend">What's the type of discount?</FormLabel>
        <RadioGroup
          row
          aria-label="discountType"
          name="discountType"
          defaultValue="end"
          value={typeOfService}
          onChange={(e) => setTypeOfService(e.target.value)}
        >
          <FormControlLabel
            value="Flat"
            control={<Radio color="primary" />}
            label="Flat"
          />
          <FormControlLabel
            value="Percentage"
            control={<Radio color="primary" />}
            label="Percentage"
          />
        </RadioGroup>
      </FormControl>
      <div className="fill-width">
        {typeOfService === 'Flat' && (
          <TextField
            value={flatAmount}
            id="standard-basic"
            label="Amount"
            onChange={(e) => setFlatAmount(e.target.value)}
            margin="normal"
            InputProps={{
              endAdornment: <InputAdornment position="end">£</InputAdornment>,
            }}
            placeholder="e.g. 30"
            type="number"
            style={{ marginRight: 10 }}
          />
        )}
        {typeOfService === 'Percentage' && (
          <TextField
            value={percentageAmount}
            label="Percentage"
            onChange={(e) => setPercentageAmount(e.target.value)}
            margin="normal"
            helperText={
              !(percentageAmount > 0 && percentageAmount <= 100)
                ? 'Amount should be between 0 and 100%'
                : ''
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            placeholder="e.g. 30"
            type="number"
            style={{ marginRight: 10 }}
          />
        )}
        <TextField
          value={code}
          id="standard-basic"
          label="Code"
          //error={code.length < 3}
          onChange={(e) => setCode(e.target.value)}
          margin="normal"
          helperText={
            code.length < 3 ? 'Code should be at least 3 characters long' : ''
          }
          placeholder="e.g. LASER50"
          type="text"
          style={{ marginRight: 10 }}
        />
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedAllProducts}
                onChange={() => setCheckedAllProducts(!checkedAllProducts)}
                name="checkedAllProducts"
                color="primary"
              />
            }
            label="Can be used for any service (1 for each cart)?"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedUniqueUse}
                onChange={() => setCheckedUniqueUse(!checkedUniqueUse)}
                name="checkedUniqueUse"
                color="primary"
              />
            }
            label="Is unique use?"
          />
        </FormGroup>
        <br />
        <FormControl margin="normal">
          <InputLabel htmlFor="age-native-simple">
            Fill with category
          </InputLabel>
          <Select
            native
            value={category}
            key={category}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            inputProps={{
              name: 'category',
              id: 'category-selector',
            }}
            //fullWidth
          >
            <option value=""></option>
            {categories.map((category) => {
              return (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              );
            })}
          </Select>
        </FormControl>
        <br />
        <Button
          onClick={() => handleAddService()}
          color="primary"
          disabled={services.length > 0 && checkedFreeService}
          style={{ marginTop: 20, marginRight: 15 }}
          variant="contained"
          margin="normal"
        >
          Add Service
        </Button>
        {services.map((service, index) => {
          console.log(service);
          return (
            <FormControl margin="normal" key={service}>
              <InputLabel htmlFor="age-native-simple">
                Service {index + 1}
              </InputLabel>
              <Select
                native
                value={service}
                key={service}
                onChange={(e) =>
                  setServices(
                    services.map((item, _index) =>
                      index == _index ? e.target.value : item
                    )
                  )
                }
                inputProps={{
                  name: 'age',
                  id: 'age-native-simple',
                }}
                // fullWidth
              >
                <option value=""></option>
                {allServices.map((serviceOptions) => {
                  return (
                    <option key={serviceOptions.id} value={serviceOptions.id}>
                      {serviceOptions.name}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          );
        })}
        <br />
        <FormControl component="fieldset">
          <FormGroup
            aria-label="position"
            row
            style={{ marginLeft: 5, marginTop: 20 }}
          >
            <FormControlLabel
              value="CheckedFreeService"
              control={
                <Switch
                  checked={checkedFreeService}
                  onChange={(event) =>
                    setCheckedFreeService(event.target.checked)
                  }
                  name="checkedB"
                  color="primary"
                />
              }
              label="Offers discount to other service"
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
        {checkedFreeService && (
          <FormControl margin="normal">
            <InputLabel htmlFor="age-native-simple">Bundled service</InputLabel>
            <Select
              native
              value={freeService}
              onChange={(e) => {
                setFreeService(e.target.value);
              }}
              inputProps={{
                name: 'age',
                id: 'age-native-simple',
              }}
              fullWidth
            >
              <option value=""></option>
              {allServices.map((serviceOptions) => {
                if (serviceOptions.id != services[0])
                  return (
                    <option key={serviceOptions.id} value={serviceOptions.id}>
                      {serviceOptions.name}
                    </option>
                  );
              })}
            </Select>
          </FormControl>
        )}
        {loading ? (
          <CircularProgress size={26} />
        ) : (
          <Button
            color="primary"
            style={{ marginTop: 20 }}
            variant="contained"
            margin="normal"
            fullWidth
            onClick={async () => await handleSaleCreation()}
            disabled={
              loading ||
              code.length < 3 ||
              ((services.length === 0 || services[0] == '') &&
                !checkedAllProducts) ||
              !(typeOfService === 'Percentage'
                ? percentageAmount > 0 && percentageAmount < 100
                : flatAmount > 0)
            }
          >
            Create
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}

export default SalesCreator;
