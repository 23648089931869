import {
  Grid
} from '@material-ui/core';
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import React, { useContext, useEffect, useState } from 'react';
import { NotificationContext } from '../../../components/Layout/notificationContext';
// components
import PageTitle from '../../../components/PageTitle/PageTitle';
import Widget from '../../../components/Widget/Widget';
import { API_BASE, fetchHomeItems } from '../../../helpers/api';
import HomeItemCreator from './HomeItemCreator';

export default function HomeItemController() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    getItems();
  }, []);

  async function getItems() {
    try {
      const homeItems = await fetchHomeItems(true);
      console.log(homeItems);
      var parsedHomeItems = [];
      for (var i = 0; i < homeItems.length; ++i) {
        const item = homeItems[i];
        parsedHomeItems.push([
          (item.service && item.service.name) ||
            item.categories.map((_item, index) =>
              item.categories.length - 1 == index
                ? _item.name
                : `${_item.name}, `
            ),
          `${(item.discount * 100).toFixed(2)}%`,
          item._id,
        ]);
      }
      setItems(parsedHomeItems);
    } catch (err) {
      console.log(err);
    }
  }

  const notification = useContext(NotificationContext);

  async function handleHomeItemDelete(index) {
    var deletedIndexes = [];
    index.forEach((item) => {
      deletedIndexes.push(item.dataIndex);
    });
    let deletedReqs = [];
    for (var i = 0; i < deletedIndexes.length; ++i) {
      try {
        deletedReqs.push(
          axios.delete(`${API_BASE}/home/${items[deletedIndexes[i]][2]}`)
        );
        console.log('hey');
      } catch (err) {
        console.log(err);
      }
    }
    const result = await Promise.all(deletedReqs);
    console.log(result);

    notification.current.sendToast('SUCCESS', 'Home Item Deleted successfully');
  }

  return (
    <>
      <PageTitle title="Home Manager" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title="Add new home item" upperTitle disableWidgetMenu>
            <HomeItemCreator onCreateNew={() => getItems()} />
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <MUIDataTable
            title="Home Items List"
            data={items}
            columns={['Service/Categories', 'Discount']}
            options={{
              filterType: 'checkbox',
              onRowsDelete: (item) => handleHomeItemDelete(item.data),
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
