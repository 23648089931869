import React, { useState, useEffect, useContext } from 'react';
import {
  TextField,
  Button,
  CircularProgress,
  InputLabel,
  Select,
  InputAdornment,
  FormControl,
  Typography,
} from '@material-ui/core';
import {
  fetchServices,
  createProvider,
  fetchCategories,
} from '../../helpers/api';
import { NotificationContext } from '../../components/Layout/notificationContext';

// import { Container } from './styles';

function ProviderCreator(props) {
  const [name, setName] = useState('');
  const [services, setServices] = useState([]);
  const [phone, setPhone] = useState('');
  const [allServices, setAllServices] = useState([]);
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);
  // const [days, setDays] = useState(initialDaysState);
  const [render, setRender] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      var newServices = [];
      try {
        newServices = await fetchServices();
        setAllServices(newServices);
        console.log(newServices);
      } catch (err) {
        console.log(err);
      }
      setAllServices(newServices);
    }
    fetchData();
    getCategories();
  }, []);

  useEffect(() => {
    if (!category) return;
    let filteredServices = allServices.filter(
      (item) => item.rawCategory == category
    );
    filteredServices = filteredServices.concat(
      allServices.filter((item) => services.includes(item.id))
    );
    filteredServices = Array.from(new Set(filteredServices));
    let array = [];
    for (let i = 0; i < filteredServices.length; i++) {
      array.push(filteredServices[i].id);
    }
    setServices(array);
  }, [category]);

  const getCategories = async () => {
    try {
      const categories = await fetchCategories();
      setCategories(categories);
    } catch (err) {
      console.log(err);
    }
  };

  const notification = useContext(NotificationContext);

  const handleServiceCreation = async () => {
    setLoading(true);
    try {
      const response = await createProvider(
        name,
        services,
        initialDaysState,
        phone
      );
      console.log(response);
      notification.current.sendToast(
        'SUCCESS',
        'Provider Created successfully'
      );
      props.onCreateNew();
      setServices([]);
      setName('');
      setPhone('');
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleAddService = () => {
    const newService = '';
    var servicesO = services;
    servicesO.push(newService);
    console.log(services);
    setServices(servicesO);
    setRender(!render);
  };

  return (
    <React.Fragment>
      <div className="fill-width">
        <TextField
          value={name}
          id="standard-basic"
          label="Name"
          onChange={(e) => setName(e.target.value)}
          margin="normal"
          placeholder="e.g. John Doe"
          type="text"
          fullWidth
        />
        <TextField
          value={phone}
          id="standard-basic"
          label="Phone"
          onChange={(e) => setPhone(e.target.value)}
          margin="normal"
          placeholder="+4411122223333"
          type="tel"
          fullWidth
        />
        <Button
          onClick={() => handleAddService()}
          color="primary"
          style={{
            marginTop: 20,
            marginRight: 15,
            marginBottom: services.length ? 0 : 20,
          }}
          variant="contained"
          margin="normal"
        >
          Add Service
        </Button>

        {services.map((service, index) => {
          return (
            <FormControl margin="normal" key={service || index}>
              <InputLabel htmlFor="age-native-simple">
                Service {index + 1}
              </InputLabel>
              <Select
                native
                value={service}
                //key={service}
                onChange={(e) => {
                  console.log(e.target.value);
                  var servicesO = services;
                  servicesO[index] = e.target.value;
                  //console.log(servicesO);
                  setServices(servicesO);
                  setRender(!render);
                  // setServices(e.target.value);
                }}
                inputProps={{
                  name: 'age',
                  id: 'age-native-simple',
                }}
                fullWidth
              >
                <option value=""></option>
                {allServices.map((serviceOptions) => {
                  return (
                    <option key={serviceOptions.id} value={serviceOptions.id}>
                      {serviceOptions.name}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          );
        })}
        <Typography>Fill services with category</Typography>
        <FormControl margin="normal">
          <InputLabel htmlFor="category-selector">
            Fill with category
          </InputLabel>
          <Select
            native
            value={category}
            key={category}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            inputProps={{
              name: 'category',
              id: 'category-selector',
            }}
            fullWidth
          >
            <option value=""></option>
            {categories.map((category) => {
              return (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              );
            })}
          </Select>
        </FormControl>
        {loading ? (
          <CircularProgress size={26} />
        ) : (
          <Button
            color="primary"
            style={{ marginTop: 20 }}
            variant="contained"
            margin="normal"
            fullWidth
            onClick={async () => await handleServiceCreation()}
            disabled={
              loading ||
              name.length < 3 ||
              services.length === 0 ||
              services[0] == '' ||
              !phone.match(
                /^(((\+44\s?\d{4})\s?\d{3}\s?\d{3})|((\+44\s?\d{3})\s?\d{3}\s?\d{4})|((\+44\s?\d{2})\s?\d{4}\s?\d{4}))?$/
              )
            }
          >
            Create
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}

const initialDaysState = [
  {
    day: 'Monday',
    value: 1,
    slots: [
      [36, 48],
      [52, 68],
    ],
  },
  {
    day: 'Tuesday',
    value: 1,
    slots: [
      [36, 48],
      [52, 68],
    ],
  },
  {
    day: 'Wednesday',
    value: 1,
    slots: [
      [36, 48],
      [52, 68],
    ],
  },
  {
    day: 'Thursday',
    value: 1,
    slots: [
      [36, 48],
      [52, 68],
    ],
  },
  {
    day: 'Friday',
    value: 1,
    slots: [
      [36, 48],
      [52, 68],
    ],
  },
  {
    day: 'Saturday',
    value: 0,
    slots: [],
  },
  {
    day: 'Sunday',
    value: 0,
    slots: [],
  },
];

export default ProviderCreator;
