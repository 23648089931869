import React from 'react';
import { useToastStyles } from './toastStyles';

import { Close as CloseIcon } from '@material-ui/icons';
import { ToastContainer, toast } from 'react-toastify';
import Notification from '../../../components/Notification/Notification';
import { forwardRef } from 'react';

// import { Container } from './styles';

export default forwardRef((props, ref) => {
  const toastStyles = useToastStyles();

  const sendToast = (type, message) => {
    const componentProps = {
      type: 'shipped',
      message: message,
      variant: 'contained',
      color: 'success',
    };
    switch (type) {
      case 'SUCCESS':
        toast(
          <Notification
            {...componentProps}
            className={toastStyles.notificationComponent}
          />,
          {
            type: 'success',
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: toastStyles.progress,
            className: toastStyles.notification,
          }
        );
        break;
      case 'ERROR':
        toast.error('There was an error during the update!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    }
  };

  if (ref) ref.current = { sendToast };

  return (
    <ToastContainer
      className={toastStyles.toastsContainer}
      closeButton={
        <CloseButton className={toastStyles.notificationCloseButton} />
      }
      closeOnClick={false}
      progressClassName={toastStyles.notificationProgress}
    />
  );
});

function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}
