import React, { useState, useEffect } from 'react';

import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';

import 'react-big-calendar/lib/sass/styles.scss';

// components
import PageTitle from '../../../components/PageTitle/PageTitle';
import Widget from '../../../components/Widget/Widget';

import { fetchProviders, fetchAppointments } from '../../../helpers/api';
import {
  Grid,
  Paper,
  Tabs,
  Tab,
  Box,
  Divider,
  DialogTitle,
  DialogContentText,
  Dialog,
  makeStyles,
} from '@material-ui/core';
import ResourceCalendar from './components/Resource';
import Basic from './components/Basic';
import AppointmentDetail from '../appointmentDetail/AppointmentDetail';
import AppointmentCreator from '../appointments/appointmentCreator';
import { useHistory } from 'react-router-dom';

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment); // or globalizeLocalizer

let allViews = Object.keys(Views).map((k) => Views[k]);

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(0),
  },
}));

export default function CalendarView(props) {
  const [value, setValue] = React.useState(0);
  const [detailOpened, setDetailOpened] = React.useState(false);
  const [appointmentToLoad, setAppointmentToLoad] = useState('');
  const [appointmentCreatorState, setAppointmentCreatorState] = useState({
    isEditing: false,
    appointmentToEdit: {},
  });

  const classes = useStyles();
  const history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openDetail = (appointmentId) => {
    setAppointmentToLoad(appointmentId);
    setDetailOpened(true);
  };

  const closeDetail = () => {
    setDetailOpened(false);
  };

  const handleAppointmentToEditSelected = (appointment) => {
    setAppointmentCreatorState({
      isEditing: true,
      appointmentToEdit: appointment,
    });
  };

  const closeAppointmentEditor = () => {
    setAppointmentCreatorState({
      isEditing: false,
      appointmentToEdit: {},
    });
  };

  // const openDetailScreen = (id) => {
  //   props.history.push({ pathname: '/app/appointments', service: id });
  // };

  const screens = [
    <ResourceCalendar openDetail={openDetail} />,
    <Basic openDetail={openDetail} />,
  ];
  const titles = [
    'Seeing calendar segmented by provider',
    'Seeing general appointments view',
  ];

  const handleScheduleReturn = (appointment) => {
    history.push({
      pathname: '/app/appointments',
      scheduleReturnState: { ...appointment },
    });
  };

  return (
    <>
      <PageTitle title="Calendar" />
      <Grid container spacing={4}>
        {detailOpened && (
          <Grid item xs={12}>
            <Widget title="Appointment Details" upperTitle disableWidgetMenu>
              <AppointmentDetail
                openDetail={openDetail}
                closeDetail={closeDetail}
                appointmentToLoad={appointmentToLoad}
                handleEditAppointment={{
                  select: handleAppointmentToEditSelected,
                  close: closeAppointmentEditor,
                }}
                scheduleReturn={handleScheduleReturn}
              />
            </Widget>
          </Grid>
        )}
        <Grid item xs={12}>
          <Widget title={`${titles[value]}`} upperTitle disableWidgetMenu>
            <Box mb={2}>
              <Paper square>
                <Tabs
                  value={value}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChange}
                  aria-label="disabled tabs example"
                >
                  <Tab label="By Provider" />
                  <Tab label="General schedule" />
                </Tabs>
              </Paper>
            </Box>
            <Box mb={2}>
              <Divider />
            </Box>
            {screens[value]}
            {/* <Calendar
              events={appointments}
              localizer={localizer}
              defaultView={Views.DAY}
              views={['day', 'work_week', 'week', 'agenda', 'month']}
              style={{ height: 500 }}
              step={30}
              defaultDate={new Date()}
              resources={providers}
              resourceIdAccessor="resourceId"
              resourceTitleAccessor="resourceTitle"
            /> */}
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Dialog
            open={appointmentCreatorState.isEditing}
            onClose={closeAppointmentEditor}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Edit Appointment</DialogTitle>
            <div className={classes.dialog}>
              <DialogContentText>
                Edit the appointment attributes here:
              </DialogContentText>
              <AppointmentCreator
                isEditing={appointmentCreatorState.isEditing}
                appointmentToEdit={appointmentCreatorState.appointmentToEdit}
              />
            </div>
          </Dialog>
        </Grid>
      </Grid>
    </>
  );
}
