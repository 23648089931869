import React, { useEffect, useState } from 'react';
import { fetchAppointments } from '../../../helpers/api';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
import { Button } from '../../../components/Wrappers/Wrappers';

// import { Container } from './styles';

function AppointmentLister(props) {
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    getAppointments();
  }, []);

  async function getAppointments() {
    try {
      const rawAppointments = await fetchAppointments();
      setAppointments(parseAppointments(rawAppointments));
    } catch (err) {
      console.log(err);
    }
  }

  props.reference.current = { reloadAppointments: getAppointments };

  const setDetail = (id) => {
    props.openDetail(id);
  };

  const paymentStatus = (item) => {
    return item.canceled
      ? 'Canceled'
      : item.paid
      ? item.asAdmin
        ? 'To pay'
        : item.inPlacePayment
        ? 'Payment in Place'
        : 'Paid'
      : 'Pending';
  };

  function parseAppointments(appointments) {
    var parsedAppointments = [];
    for (var i = 0; i < appointments.length; ++i) {
      const item = appointments[i];
      parsedAppointments.push([
        item.provider ? item.provider.name : '',
        moment(item.slot_id.slot_date).format('DD[/]MM[/]YYYY'), //.format('dddd[,] MMMM Do[,] YYYY'),
        item.service ? item.service.name : '',
        item.name,
        item.canceled
          ? 0
          : item.chargedPrice
          ? item.chargedPrice
          : item.service
          ? item.service.price
          : '',
        paymentStatus(item),
        item._id,
      ]);
    }
    return parsedAppointments;
  }

  const states = {
    paid: 'success',
    pending: 'warning',
    canceled: 'secondary',
    'to pay': 'info',
    'payment in place': 'primary',
  };

  const statusCollumn = {
    name: 'Status',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => (
        <Button
          color={states[value.toLowerCase()]}
          size="small"
          className="px-2"
          variant="contained"
        >
          {value}
        </Button>
      ),
    },
  };

  return (
    <MUIDataTable
      title="Appointments List"
      data={appointments}
      columns={[
        'Provider',
        'Date',
        'Service',
        'Client',
        'Price £',
        statusCollumn,
      ]}
      options={{
        filterType: 'dropdown',
        onRowClick: (_, index) =>
          setDetail(appointments[index.dataIndex][appointments[0].length - 1]),
        // onRowsDelete: (item) => handleCategoryDelete(item.data),
      }}
    />
  );
}

export default AppointmentLister;
