import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
// components
import PageTitle from '../../../components/PageTitle/PageTitle';
import PurchasesLister from './purchasesLister';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 220px)',
  },
}));

export default function Accounting() {
  const classes = useStyles();
  return (
    <>
      <PageTitle title="Accounting" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <div className={classes.flex}>
            <PurchasesLister />
            <PurchasesLister timeAmount={30} />
            <PurchasesLister provider />
          </div>
        </Grid>
      </Grid>
    </>
  );
}
