import axios from 'axios';
// Handles all the connection to api (backend) endpoints and directly returns the useful values

export const API_BASE = process.env.REACT_APP_API_URL;

export const axiosDefaultInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export async function createCategory(name) {
  const newCategory = { name };
  try {
    const response = await axios.post(
      `${API_BASE}/category/admin/create`,
      newCategory
    );
    return response;
  } catch (err) {
    throw err;
  }
}

export async function fetchCategories() {
  try {
    const response = await axios.get(`${API_BASE}/category/list`);
    var categories = [];
    for (var i = 0; i < response.data.length; ++i) {
      categories.push({
        id: response.data[i]._id,
        name: response.data[i].name,
      });
    }
    return categories;
  } catch (err) {
    throw err;
  }
}

export async function createService(name, category, timeInterval, price) {
  const newService = {
    name,
    category,
    timeInterval,
    price,
  };
  try {
    const response = await axios.post(
      `${API_BASE}/service/admin/create`,
      newService
    );
    return response;
  } catch (err) {
    throw err;
  }
}

export async function fetchServices() {
  try {
    const response = await axios.get(`${API_BASE}/service/list`);
    var services = [];
    for (var i = 0; i < response.data.length; ++i) {
      services.push({
        id: response.data[i]._id,
        name: response.data[i].name,
        category: response.data[i].category.name,
        price: response.data[i].price,
        duration: `${response.data[i].timeInterval * 15} min`,
        rawCategory: response.data[i].category._id,
        timeInterval: response.data[i].timeInterval,
      });
    }
    return services;
  } catch (err) {
    throw err;
  }
}

export async function fetchAppointments() {
  try {
    const response = await axios.get(`${API_BASE}/appointment/list`);
    // var services = [];
    // for (var i = 0; i < response.data.length; ++i) {
    //   services.push({
    //     id: response.data[i]._id,
    //     name: response.data[i].name,
    //     category: response.data[i].category.name,
    //     price: response.data[i].price,
    //     duration: `${response.data[i].timeInterval * 15} min`,
    //   });
    // }
    console.log(response.data);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function fetchAppointment(id) {
  try {
    const response = await axios.get(`${API_BASE}/appointment/byId/${id}`);
    console.log(response.data);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function fetchProviders() {
  try {
    const response = await axios.get(`${API_BASE}/provider/list`);
    var providers = [];

    for (var i = 0; i < response.data.length; ++i) {
      var services = '';
      var serviceIds = [];
      for (var j = 0; j < response.data[i].services.length; ++j) {
        if (j != response.data[i].services.length - 1)
          services += ` ${response.data[i].services[j].name},`;
        else if (j == 0) services += `${response.data[i].services[j].name},`;
        else services += ` ${response.data[i].services[j].name}`;
        serviceIds.push(response.data[i].services[j]._id);
      }
      providers.push({
        _id: response.data[i]._id,
        name: response.data[i].name,
        services: services,
        days: response.data[i].days,
        rawServices: serviceIds,
        phone: response.data[i].phone,
      });
    }
    return providers;
  } catch (err) {
    throw err;
  }
}

export async function createProvider(name, services, days, phone) {
  var providerDays = [];
  const weekDays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  var servicesSet = new Set();
  for (var k = 0; k < services.length; k++)
    if (services[k] != '') servicesSet.add(services[k]);

  // for(var l=0; l<servicesSet.size; ++l) {

  // }
  for (var i = 0; i < 7; ++i) {
    const newDay = {
      day: weekDays[i],
      value: days[i].value,
      slots: days[i].slots,
    };
    providerDays.push(newDay);
  }

  const newProvider = {
    name,
    services: [...servicesSet],
    days: providerDays,
    phone,
  };

  console.log(newProvider);

  try {
    const response = await axios.post(
      `${API_BASE}/provider/admin/create`,
      newProvider
    );
    return response;
  } catch (err) {
    throw err;
  }
}

export async function deleteCategory(id) {
  try {
    const response = await axios.delete(`${API_BASE}/category/delete/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
}

export async function deleteService(id) {
  try {
    const response = await axios.delete(`${API_BASE}/service/delete/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
}

export async function createSale({
  percentageAmount,
  flatAmount,
  typeOfService,
  allCart,
  isUniqueUse,
  code,
  service,
  freeService,
}) {
  const amountValue =
    typeOfService === 'Percentage' ? percentageAmount / 100 : flatAmount;

  const newSale = {
    amount: { mode: typeOfService, value: amountValue },
    allCart,
    isUniqueUse,
    code,
    service,
    isFreeService: freeService != '',
    freeService: freeService,
  };

  try {
    const response = await axios.post(`${API_BASE}/sales`, newSale);
    return response;
  } catch (err) {
    throw err;
  }
}
export async function fetchSales() {
  try {
    const response = await axios.get(`${API_BASE}/sales`);
    return response.data;
  } catch (err) {
    throw err;
  }
}
export async function deleteSale(id) {
  try {
    const response = await axios.delete(`${API_BASE}/sales/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
}

export async function createNotificationModels(headline, body) {
  const newNotificationModel = { headline, body };
  const headers = {
    Authorization: 'Bearer ' + localStorage.getItem('id_token'),
  };
  try {
    const response = await axios.post(
      `${API_BASE}/notificationModel`,
      newNotificationModel,
      { headers }
    );
    return response;
  } catch (err) {
    throw err;
  }
}
export async function fetchNotificationModels() {
  try {
    const response = await axios.get(`${API_BASE}/notificationModel/list`);
    return response.data;
  } catch (err) {
    throw err;
  }
}
export async function deleteNotificationModel(id) {
  try {
    const response = await axios.delete(`${API_BASE}/notificationModel/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
}

export async function deleteProvider(id) {
  try {
    const response = await axios.delete(`${API_BASE}/provider/delete/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
}

export async function fetchHomeItems() {
  try {
    const response = await axios.get(`${API_BASE}/home`);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function createHomeItem(
  discount,
  service,
  image,
  price,
  categories,
  onlyImage,
  updating = false,
  id = ''
) {
  console.log(onlyImage);

  const formData = new FormData();
  if (onlyImage) formData.append('onlyImage', onlyImage);
  if (service) formData.append('service', service);
  if (categories) formData.append('categories', categories);
  if (discount && !price) formData.append('discount', discount / 100);
  if (price) formData.append('price', price);
  if (image) formData.append('image', image);
  const newHomeItem = {
    discount: discount / 100,
    service,
    image,
  };
  try {
    let response;
    if (updating)
      response = await axios.put(`${API_BASE}/home/${id}`, formData);
    else response = await axios.post(`${API_BASE}/home`, formData);
    return response;
  } catch (err) {
    throw err;
  }
}

export async function importAppointmentsTable(data) {
  console.log(data.excelTable);

  const formData = new FormData();
  formData.append('table', data.excelTable);

  const response = await axios.post(
    `${API_BASE}/appointment/importAppointments`,
    formData
  );

  console.log(response.data);

  return response.data;
}

export async function fetchHolidays() {
  try {
    const response = await axiosDefaultInstance.get(`/holiday/list`);

    return response.data;
  } catch (err) {
    throw err;
  }
}
export async function deleteHoliday(id) {
  try {
    const response = await axiosDefaultInstance.delete(`/holiday/${id}`);

    return response;
  } catch (err) {
    throw err;
  }
}
