import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, CircularProgress } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { API_BASE } from '../../../helpers/api';
import axios from 'axios';
import moment from 'moment';
import { NotificationContext } from '../../../components/Layout/notificationContext';

function PaymentReminderCreator(props) {
  const [telephone, setTelephone] = useState('');
  const [email, setEmail] = useState('');
  const [timePeriod, setTimePeriod] = useState(0);
  const [firstContact, setFirstContact] = useState('');
  const [loading, setLoading] = useState(false);

  const createPaymentReminder = async () => {
    setLoading(true);
    if (!checkValid) return;
    try {
      const response = await axios.post(
        `${API_BASE}/notification/paymentReminder`,
        { telephone, email, timePeriod, nextContact: firstContact }
      );
      cleanState();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const notification = useContext(NotificationContext);

  const cleanState = () => {
    setTelephone('');
    setEmail('');
    setTimePeriod(0);
    setFirstContact(new Date());
    notification.current.sendToast(
      'SUCCESS',
      'Payment notification created sucessfully'
    );
  };

  const checkValid = () => {
    return (
      telephone.length > 9 &&
      email.length > 2 &&
      timePeriod > 0 &&
      new Date(firstContact).getTime() >= new Date().getTime()
    );
  };

  return (
    <React.Fragment>
      <div className="fill-width">
        <TextField
          value={email}
          id="standard-basic"
          label="Email"
          //error={!(amount > 0 && amount <= 100)}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          helperText={
            email.length < 3 ? 'Email should be at least 3 characters long' : ''
          }
          placeholder="e.g. New Offer Online"
          type="text"
          fullWidth
          style={{ marginRight: 10 }}
        />
        <TextField
          value={telephone}
          id="standard-basic"
          label="Telephone"
          //error={code.length < 3}
          onChange={(e) => setTelephone(e.target.value)}
          margin="normal"
          helperText={
            telephone.length < 9
              ? 'telephone should be at least 9 characters long'
              : ''
          }
          placeholder="e.g. Check the new discounts in laser waxing! Online for limited time!"
          type="text"
          variant="outlined"
          fullWidth
          style={{ marginRight: 10 }}
        />
        <TextField
          value={timePeriod}
          id="standard-basic"
          label="Time Period of contact (in days)"
          //error={code.length < 3}
          onChange={(e) => setTimePeriod(e.target.value)}
          margin="normal"
          helperText={timePeriod.length <= 0 ? 'Should be greater than 0' : ''}
          placeholder="e.g. Check the new discounts in laser waxing! Online for limited time!"
          type="number"
          variant="outlined"
          fullWidth
          style={{ marginRight: 10 }}
        />
        <DatePicker
          value={firstContact}
          onChange={(date) => setFirstContact(date)}
          margin="normal"
          placeholder="Select Date"
          id="date-picker-dialog"
          label="First Contact"
          format="MM/DD/yyyy"
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          disablePast
          helperText={
            new Date(firstContact).getTime() <= new Date().getTime()
              ? 'Must be after today'
              : ''
          }
          error={new Date(firstContact).getTime() <= new Date().getTime()}
        />
        {loading ? (
          <CircularProgress size={26} />
        ) : (
          <Button
            color="primary"
            style={{ marginTop: 20 }}
            variant="contained"
            margin="normal"
            fullWidth
            onClick={() => createPaymentReminder()}
            disabled={loading || !checkValid()}
          >
            Create
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}

export default PaymentReminderCreator;
