import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';

// components
import PageTitle from '../../../components/PageTitle/PageTitle';
import Widget from '../../../components/Widget/Widget';
import {
  deleteService,
  fetchSales,
  deleteSale,
  fetchProviders,
} from '../../../helpers/api';
import ServiceCreator from '../ServiceCreator';
import { ToastContainer } from 'react-toastify';
import AppointmentLister from '../appointments/AppointmentLister';
import Basic from '../calendar/components/Basic';
import ProvidersTimeTable from '../../../components/Table/ProviderTimeTable';
import ProviderAppointmentLister from '../appointments/ProviderAppointmentLister';
import { useUserState } from '../../../helpers/UserContext';
import AppointmentDetail from '../appointmentDetail/AppointmentDetail';

export default function TeacherView() {
  const [providersSchedule, setProvidersSchedule] = useState([]);
  const [detailOpened, setDetailOpened] = useState(false);
  const [appointmentToLoad, setAppointmentToLoad] = useState('');

  const { user } = useUserState();

  useEffect(() => {
    getProvidersSchedule();
  }, []);

  const getProvidersSchedule = async () => {
    //setLoading(true);
    try {
      const rawProviders = await fetchProviders();
      var parsedProviders = [];
      for (var i = 0; i < rawProviders.length; ++i) {
        const item = rawProviders[i];
        console.log(user.provider._id);
        if (user.provider == item._id)
          parsedProviders.push({
            name: item.name,
            _id: item._id,
            data: item.days,
          });
      }
      setProvidersSchedule(parsedProviders);
    } catch (err) {
      console.log(err);
    }
    //setLoading(false);
  };

  const openDetail = (appointment) => {
    setAppointmentToLoad(appointment);
    setDetailOpened(true);
  };
  const closeDetail = () => {
    setDetailOpened(false);
    setAppointmentToLoad('');
  };

  return (
    <>
      <ToastContainer />
      <PageTitle title="Manager" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title="Your calendar" upperTitle disableWidgetMenu>
            <Basic />
          </Widget>
        </Grid>
        {detailOpened && (
          <Grid item xs={12}>
            <Widget title="Appointment Details" upperTitle disableWidgetMenu>
              <AppointmentDetail
                openDetail={openDetail}
                closeDetail={closeDetail}
                appointmentToLoad={appointmentToLoad}
              />
            </Widget>
          </Grid>
        )}
        <Grid item xs={12}>
          <Widget title="Your appointments list" upperTitle disableWidgetMenu>
            <ProviderAppointmentLister openDetail={openDetail} />
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget title="Your shift" upperTitle disableWidgetMenu>
            <ProvidersTimeTable
              data={providersSchedule}
              refreshSchedule={() => getProvidersSchedule()}
            />
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
