import React, { useState, useEffect, useMemo, useContext } from 'react';
import {
  TextField,
  Button,
  CircularProgress,
  InputLabel,
  Select,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Divider,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

import axios from 'axios';
import {
  API_BASE,
  fetchServices,
  createService,
  fetchCategories,
  fetchProviders,
} from '../../../helpers/api';
import { DatePicker } from '@material-ui/pickers';
import {
  buildTimeStringsArray,
  slotTimeToString,
  timeStringToSlotNumber,
} from '../../../helpers/timeSlotHelpers';
import moment from 'moment';
import { useUserState } from '../../../helpers/UserContext';
import { NotificationContext } from '../../../components/Layout/notificationContext';

// import { Container } from './styles';

const useStyles = makeStyles({
  distribuiter: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  inputHolder: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 10,
    marginBottom: 10,
  },
  spacer: {
    marginRight: 20,
  },
});

function AppointmentCreator({ onCreateNew, ...props }) {
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [services, setServices] = useState([]);
  const [service, setService] = useState('');
  const [loading, setLoading] = useState(false);
  const [occupiedSlots, setOccupiedSlots] = useState([]);
  const [time, setTime] = useState('');
  const [providers, setProviders] = useState([]);
  const [provider, setProvider] = useState('');
  const [date, setDate] = useState(new Date());
  const [password, setPassword] = useState('');
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);

  const classes = useStyles();

  const { user } = useUserState();

  const notification = useContext(NotificationContext);

  useEffect(() => {
    getServices();
    getProviders();
    getOccupiedSlots();
    getCategories();
  }, []);

  useEffect(() => {
    if (props.initialState && Object.keys(props.initialState).length) {
      if (props.initialState.name) setName(props.initialState.name);
      if (props.initialState.lastName) setLastName(props.initialState.lastName);
      if (props.initialState.email) setEmail(props.initialState.email);
      if (props.initialState.phone) setPhone(props.initialState.phone);
      if (props.initialState.service)
        setService(props.initialState.service._id);
      if (props.initialState.provider)
        setProvider(props.initialState.provider._id);
    }
  }, [props.initialState]);

  useEffect(() => {
    if (props.isEditing && props.appointmentToEdit) populateFields();
  }, [props.isEditing]);

  const populateFields = () => {
    setName(props.appointmentToEdit.name);
    setLastName(props.appointmentToEdit.lastName);
    setEmail(props.appointmentToEdit.email);
    setPhone(props.appointmentToEdit.phone);
    setService(props.appointmentToEdit.service._id);
    setTime(
      `${slotTimeToString(
        props.appointmentToEdit.slots[0]
      )} - ${slotTimeToString(props.appointmentToEdit.slots[1])}`
    );
    setProvider(props.appointmentToEdit.provider._id);
    setDate(new Date(props.appointmentToEdit.slot_id.slot_date));
  };

  const occupiedForProvider = useMemo(
    () => occupiedSlots.filter((item) => item.provider._id == provider),
    [provider]
  );

  const servicesToDisplay = useMemo(() => {
    if (!category) return services;

    return services.filter((item) => `${item.rawCategory}` === category);
  }, [category, services]);

  async function getServices() {
    var newServices = [];
    try {
      newServices = await fetchServices();
      setServices(newServices);
    } catch (err) {
      console.log(err);
    }
  }

  async function getProviders() {
    try {
      const providers = await fetchProviders();
      console.log(providers);
      setProviders(providers);
    } catch (err) {
      console.log(err);
    }
  }

  async function getOccupiedSlots() {
    const _occupiedSlots = await axios.get(
      `${API_BASE}/appointment/slots/future`
    );
    setOccupiedSlots(_occupiedSlots.data.slots);
  }

  async function getCategories() {
    const _categories = await fetchCategories();

    setCategories(_categories);
  }

  const createAppointment = async () => {
    setLoading(true);
    try {
      // console.log(user, password);
      const body = {
        slot_time: [
          timeStringToSlotNumber(time.split(' - ')[0]),
          timeStringToSlotNumber(time.split(' - ')[1]),
        ],
        slot_date: moment(date).format('YYYY-MM-DD').toString(),
        provider,
        service,
        name,
        email,
        phone,
        user,
        password,
      };
      if (props.isEditing) {
        const response = await axios.post(
          `${API_BASE}/appointment/editAsAdmin`,
          { ...body, appointment: props.appointmentToEdit }
        );
      } else {
        const response = await axios.post(
          `${API_BASE}/appointment/createAsAdmin`,
          body
        );
      }

      notification.current.sendToast(
        'SUCCESS',
        'New appointment created successfully'
      );

      props.reloadAppointments();
      getOccupiedSlots();
      setTime('');
      // setProvider('');
      // setPassword('');
      // setDate(new Date());

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <InputLabel htmlFor="age-native-simple">Costumer Info</InputLabel>
      <div className="fill-width">
        <TextField
          value={name}
          id="standard-basic"
          label="Name"
          onChange={(e) => setName(e.target.value)}
          margin="normal"
          placeholder="e.g. Laser Hollywood arm"
          type="text"
          fullWidth
        />
        <div className={classes.distribuiter}>
          <TextField
            value={lastName}
            id="standard-basic"
            label="Last Name"
            onChange={(e) => setLastName(e.target.value)}
            margin="normal"
            placeholder="e.g. 40"
            type="text"
          />
          <TextField
            value={email}
            id="standard-basic"
            label="Email"
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            placeholder="e.g. person@gmail.com"
            type="email"
            style={{ flex: 1, marginLeft: 20, minWidth: 100 }}
          />
          <TextField
            value={phone}
            id="standard-basic"
            label="Phone"
            onChange={(e) => setPhone(e.target.value)}
            margin="normal"
            placeholder="e.g. +447592194"
            type="phone"
            style={{ flex: 1, marginLeft: 20, minWidth: 100 }}
          />
        </div>
        <FormGroup>
          <div>
            <InputLabel>
              What's the category of the service? (optional)
            </InputLabel>
            <Select
              native
              value={category}
              style={{ marginBottom: 10 }}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value={''}></option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Select>

            <InputLabel>What's the service?</InputLabel>
            <Select
              native
              value={service}
              style={{ marginBottom: 10 }}
              onChange={(e) => setService(e.target.value)}
            >
              <option value={''}></option>
              {servicesToDisplay.map((service) => (
                <option key={service.id} value={service.id}>
                  {service.name}
                </option>
              ))}
            </Select>

            <InputLabel>Who's the provider responsible?</InputLabel>
            <Select
              native
              style={{ marginBottom: 10 }}
              value={provider}
              onChange={(e) => setProvider(e.target.value)}
            >
              <option value={''}></option>
              {providers
                .filter((_provider) => _provider.rawServices.includes(service))
                .map((provider) => (
                  <option key={provider._id} value={provider._id}>
                    {provider.name}
                  </option>
                ))}
            </Select>

            <div className={classes.inputHolder}>
              <div className={classes.spacer}>
                <FormLabel component="legend" style={{ marginBottom: 10 }}>
                  When will the appointment be?
                </FormLabel>
                <InputLabel htmlFor="age-native-simple">Date</InputLabel>
                <DatePicker
                  value={date}
                  style={{ marginBottom: 10 }}
                  onChange={(date) => setDate(date)}
                  placeholder="Select Date"
                  id="date-picker-dialog"
                  format="MM/DD/yyyy"
                  disablePast
                  helperText={
                    new Date(date).getTime() <= new Date().getTime()
                      ? 'Must be after today'
                      : ''
                  }
                  error={new Date(date).getTime() <= new Date().getTime()}
                />

                <InputLabel htmlFor="age-native-simple">Time Slot</InputLabel>
                <Select
                  native
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                >
                  <option value={''}></option>
                  {buildTimeStringsArray(
                    date,
                    occupiedForProvider,
                    services.find((item) => item.id == service)
                      ? services.find((item) => item.id == service).timeInterval
                      : 0,
                    providers.find((item) => item._id == provider),
                    props.appointmentToEdit &&
                      props.appointmentToEdit.slots &&
                      `${slotTimeToString(
                        props.appointmentToEdit.slots[0]
                      )} - ${slotTimeToString(
                        props.appointmentToEdit.slots[1]
                      )}`
                  ).map((item, index) => (
                    <option key={`${index}`} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              </div>
              <Divider flexItem={true} />
              <div>
                <InputLabel htmlFor="age-native-simple">
                  Just for safety, type your password
                </InputLabel>
                <TextField
                  value={password}
                  id="standard-basic"
                  label="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="e.g. myPassword123"
                  type="password"
                  style={{ flex: 1, minWidth: 100 }}
                />
              </div>
            </div>
          </div>
        </FormGroup>

        {loading ? (
          <CircularProgress size={26} />
        ) : (
          <Button
            color="primary"
            variant="contained"
            margin="normal"
            fullWidth
            onClick={async () => await createAppointment()}
            style={{ marginTop: 10 }}
            disabled={loading || name.length < 3}
          >
            {props.isEditing ? 'Update' : 'Create'}
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}

export default AppointmentCreator;
