import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  InputAdornment,
  InputLabel,
  Select,
  CircularProgress,
  Button,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import MUIDataTable from 'mui-datatables';

// components
import PageTitle from '../../../components/PageTitle/PageTitle';
import Widget from '../../../components/Widget/Widget';
import Table from '../../../components/Table/CategoriesTable';
import {
  fetchCategories,
  fetchServices,
  deleteService,
  API_BASE,
} from '../../../helpers/api';
import CategoryCreator from '../CategoryCreator';
import ServiceCreator from '../ServiceCreator';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import { useContext } from 'react';
import { NotificationContext } from '../../../components/Layout/notificationContext';

// data
// import mock from '../dashboard/mock';

const useStyles = makeStyles((theme) => ({
  distribuiter: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  dialog: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(0),
  },
}));

export default function Services() {
  // Service Editor
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [timeInterval, setTimeInterval] = useState(null);
  const [price, setPrice] = useState(null);
  const [id, setId] = useState('');
  const [editOpen, setEditOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // Service Lister
  const [services, setServices] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    getServices();
    getCategories();
  }, []);

  async function getCategories() {
    var newCategories = [];
    try {
      newCategories = await fetchCategories();
      setCategories(newCategories);
      console.log(newCategories);
    } catch (err) {
      console.log(err);
    }
  }

  async function getServices() {
    try {
      const rawServices = await fetchServices();
      var parsedServices = [];
      for (var i = 0; i < rawServices.length; ++i) {
        const item = rawServices[i];
        parsedServices.push([
          item.name,
          item.category,
          (item.price || 0).toFixed(2),
          item.duration,
          item.id,
          item.rawCategory,
        ]);
      }
      setServices(parsedServices);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleServiceDelete(index) {
    let deletedIndexes = [];
    index.forEach((item) => {
      deletedIndexes.push(item.dataIndex);
    });
    for (var i = 0; i < deletedIndexes.length; ++i) {
      try {
        const response = await deleteService(services[deletedIndexes[i]][4]);
        notification.current.sendToast(
          'SUCCESS',
          'Deleted Service Successfully'
        );
        console.log(response);
      } catch (err) {
        console.log(err);
      }
    }
  }

  const handleEditClose = () => {
    setEditOpen(false);
    setTimeInterval(null);
    setPrice(null);
    setName('');
    setCategory('');
    setLoading(false);
  };

  const notification = useContext(NotificationContext);

  async function handleServiceUpdate() {
    try {
      let newServices = Array.from(new Set(services));
      newServices = newServices.filter((item) => item.length > 2);
      const response = await axios.put(`${API_BASE}/service/update/${id}`, {
        name,
        price,
        timeInterval: timeInterval / 15,
        category,
      });
      console.log(response);
      notification.current.sendToast('SUCCESS', 'Updated Service info');
      handleEditClose();
      getServices();
    } catch (err) {
      console.log(err);
    }
  }

  const serviceEditor = () => {
    return (
      <Dialog
        open={editOpen}
        onClose={() => handleEditClose()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update Service</DialogTitle>
        <div className={classes.dialog}>
          <DialogContentText>
            Set service new info in the fields below. {'\n'}To remove the
            category, just select the empty option
          </DialogContentText>
          <div className="fill-width">
            <TextField
              value={name}
              id="standard-basic"
              label="Name"
              onChange={(e) => setName(e.target.value)}
              margin="normal"
              placeholder="e.g. Laser Hollywood arm"
              type="text"
              fullWidth
            />
            <div className={classes.distribuiter}>
              <TextField
                value={price}
                id="standard-basic"
                label="Price"
                onChange={(e) => setPrice(e.target.value)}
                margin="normal"
                placeholder="e.g. 40"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  ),
                }}
              />
              <TextField
                value={timeInterval}
                error={timeInterval % 15 != 0}
                helperText={
                  timeInterval % 15 != 0
                    ? 'Time should be a multiple of 15'
                    : ''
                }
                id="standard-basic"
                label="Duration"
                onChange={(e) => setTimeInterval(e.target.value)}
                margin="normal"
                placeholder="The duration in minutes 15, 30, 45..."
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">min</InputAdornment>
                  ),
                }}
                style={{ flex: 1, marginLeft: 20, minWidth: 100 }}
              />
            </div>
            <InputLabel htmlFor="age-native-simple">Category</InputLabel>
            <Select
              native
              value={category}
              onChange={(e) => {
                console.log(e.target.value);
                setCategory(e.target.value);
              }}
              inputProps={{
                name: 'age',
                id: 'age-native-simple',
              }}
              fullWidth
            >
              <option value=""></option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Select>
            {loading ? (
              <CircularProgress size={26} />
            ) : (
              <Button
                color="primary"
                variant="contained"
                margin="normal"
                fullWidth
                onClick={async () => await handleServiceUpdate()}
                style={{ marginTop: 10 }}
                disabled={
                  loading ||
                  name.length < 3 ||
                  category === '' ||
                  !price ||
                  !timeInterval ||
                  timeInterval % 15 != 0
                }
              >
                Update
              </Button>
            )}
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <>
      <PageTitle title="Service Manager" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title="Add new service" upperTitle disableWidgetMenu>
            <ServiceCreator
              onCreateNew={() => {
                getServices();
                notification.current.sendToast(
                  'SUCCESS',
                  'Service added successfully'
                );
              }}
            />
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <MUIDataTable
            title="Services List"
            data={services}
            columns={[
              'Name',
              'Category',
              'Price',
              {
                name: 'Duration',
                label: 'Duration',
                options: {
                  filter: true,
                  sort: false,
                },
              },
            ]}
            options={{
              filterType: 'checkbox',
              onRowsDelete: (item) => handleServiceDelete(item.data),
              onRowClick: (_, index) => {
                setId(services[index.dataIndex][4]);
                setName(services[index.dataIndex][0]);
                setCategory(services[index.dataIndex][5]);
                setPrice(services[index.dataIndex][2]);
                setTimeInterval(services[index.dataIndex][3].split(' ')[0]);
                setEditOpen(true);
              },
            }}
          />
        </Grid>
      </Grid>
      {serviceEditor()}
    </>
  );
}
