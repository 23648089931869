import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import React, { useEffect, useState, useContext } from 'react';
import { NotificationContext } from '../../../components/Layout/notificationContext';
import { fetchProviders, axiosDefaultInstance } from '../../../helpers/api';

function HolidayCreator(props) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState('');
  const [providers, setProviders] = useState([]);
  const [providersToVacation, setProvidersToVacation] = useState([]);
  const [providerType, setProviderType] = useState('all');
  const [loading, setLoading] = useState(false);

  const notification = useContext(NotificationContext);

  useEffect(() => {
    getProviders();
  }, []);

  const getProviders = async () => {
    try {
      const providers = await fetchProviders();
      setProviders(providers);
    } catch (err) {
      console.log(err);
    }
  };

  function handleSetAppointmentDate(date, when) {
    if (when == 'start') setStartDate(date);
    else setEndDate(date);
  }

  const handleCreateHoliday = async () => {
    try {
      setLoading(true);
      const data = {
        start: startDate,
        end: endDate,
      };
      if (
        providerType == 'specific' &&
        providersToVacation.length &&
        providersToVacation[0] != ''
      )
        data.providers = Array.from(new Set(providersToVacation)).filter(
          (item) => item != ''
        );

      const response = await axiosDefaultInstance.post('/holiday', data);
      notification.current.sendToast('SUCCESS', 'Holiday created successfully');
      setLoading(false);
      console.log(response.data);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleAddService = () => {
    setProvidersToVacation([...providersToVacation, '']);
  };

  const providerSelector = () => {
    return (
      <div>
        <Button
          onClick={() => handleAddService()}
          color="primary"
          style={{ marginTop: 20, marginRight: 15 }}
          variant="contained"
          margin="normal"
        >
          Add Provider
        </Button>

        {providersToVacation.map((service, index) => {
          return (
            <FormControl margin="normal">
              <InputLabel htmlFor="age-native-simple">
                Provider {index + 1}
              </InputLabel>
              <Select
                native
                value={service}
                key={service}
                onChange={(e) => {
                  setProvidersToVacation(
                    providersToVacation.map((item, _index) =>
                      index == _index ? e.target.value : item
                    )
                  );
                }}
                inputProps={{
                  name: 'age',
                  id: 'age-native-simple',
                }}
                fullWidth
              >
                <option value=""></option>
                {providers.map((provider) => {
                  return (
                    <option key={provider.id} value={provider._id}>
                      {provider.name}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          );
        })}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="fill-width">
        <FormControl component="fieldset">
          <FormLabel component="legend">Who'll be on vacation?</FormLabel>
          <RadioGroup
            row
            aria-label="vacationType"
            name="vacationType"
            defaultValue="all"
            value={providerType}
            onChange={(e) => setProviderType(e.target.value)}
          >
            <FormControlLabel
              value="all"
              control={<Radio color="primary" />}
              label="All Providers"
              labelPlacement="end"
            />
            <FormControlLabel
              value="specific"
              control={<Radio color="primary" />}
              label="Specific Providers"
              labelPlacement="end"
            />
          </RadioGroup>
        </FormControl>
        <div>
          <DatePicker
            value={startDate}
            onChange={(date) => handleSetAppointmentDate(date, 'start')}
            margin="normal"
            placeholder="Select Date"
            id="date-picker-dialog"
            label="Start Date"
            format="MM/DD/yyyy"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            disablePast
            style={{ marginRight: 10 }}
          />
          <DatePicker
            value={endDate}
            onChange={(date) => handleSetAppointmentDate(date, 'end')}
            margin="normal"
            placeholder="Select Date"
            id="date-picker-dialog"
            label="End Date"
            format="MM/DD/yyyy"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            helperText={
              new Date(startDate).getTime() >= new Date(endDate).getTime()
                ? 'Dates overlap'
                : ''
            }
            error={new Date(startDate).getTime() >= new Date(endDate).getTime()}
          />
        </div>

        {providerType === 'specific' && providerSelector()}

        {loading ? (
          <CircularProgress size={26} />
        ) : (
          <Button
            color="primary"
            style={{ marginTop: 20 }}
            variant="contained"
            margin="normal"
            fullWidth
            onClick={() => handleCreateHoliday()}
            disabled={
              !startDate ||
              !endDate ||
              new Date(startDate).getTime() >= new Date(endDate).getTime() ||
              (providerType == 'specific' &&
                (!providersToVacation.length ||
                  (providersToVacation.length && providersToVacation[0] == '')))
            }
          >
            Create
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}

export default HolidayCreator;
