import React, { useState } from 'react';
import { Grid, Typography, Tabs, Tab } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

// styles
import useStyles from './styles';

// logo
import logo from './logo.png';

// context
// import { useUserDispatch, loginUser } from '../../context/UserContext';
import SignUpComponent from './SignUp';
import SignInComponent from './SignIn';

function Login(props) {
  var classes = useStyles();

  // global
  //var userDispatch = useUserDispatch();

  // local
  var [activeTabId, setActiveTabId] = useState(0);

  var [state, setState] = useState({
    isLoading: false,
    error: null,
    nameValue: '',
    loginValue: '',
    passwordValue: '',
    providerValue: '',
    phoneValue: '',
  });

  function handleChange(param, e) {
    //console.log(param, e.target.value);
    return setState({ ...state, [param]: e.target.value });
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img
          src={`${process.env.REACT_APP_LOGO_URL}`}
          alt="logo"
          className={classes.logotypeImage}
        />
        <Typography className={classes.logotypeText}>
          Admin Dashboard
        </Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Login" classes={{ root: classes.tab }} />
            <Tab label="New User" classes={{ root: classes.tab }} />
          </Tabs>
          {activeTabId === 0 && (
            <SignInComponent {...props} editHandler={handleChange} />
          )}
          {activeTabId === 1 && (
            <SignUpComponent {...props} editHandler={handleChange} />
          )}
        </div>
        {(process.env.REACT_APP_SHOW_COPYRIGHT ||
          typeof process.env.REACT_APP_SHOW_COPYRIGHT === 'undefined') && (
          <Typography color="primary" className={classes.copyright}>
            © 2021 Scheduly. Std., LLC. All rights reserved.
          </Typography>
        )}
      </div>
    </Grid>
  );
}

export default Login;
