import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// context
import { useUserState } from '../../helpers/UserContext';

export default function AdminRoute({ component, ...rest }) {
  // global
  var { isAuthenticated, user } = useUserState();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && user.role === 2 ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
}
