import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { API_BASE } from '../../../helpers/api';

// import { Container } from './styles';

function CostumerLister(props) {
  const [costumers, setCostumers] = useState([]);

  useEffect(() => {
    getCostumers();
  }, []);

  async function getCostumers() {
    try {
      const request = await axios.get(`${API_BASE}/appointment/list/costumers`);
      const { costumers } = request.data;

      setCostumers(
        costumers.map((item) => [item.email, item.name, item.phone])
      );
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <MUIDataTable
      title="Costumer List"
      data={costumers}
      columns={['Email', 'Name', 'Phone']}
      options={{
        filterType: 'dropdown',
        onRowClick: (_, index) =>
          props.populateCreate({
            email: costumers[index.dataIndex][0],
            name: costumers[index.dataIndex][1],
            phone: costumers[index.dataIndex][2],
          }),
      }}
    />
  );
}

export default CostumerLister;
