import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  DialogActions,
  TextField,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Dialog,
  FormControl,
  InputLabel,
  Select,
  Typography,
} from '@material-ui/core';
import MUIDataTable from 'mui-datatables';

// components
import PageTitle from '../../../components/PageTitle/PageTitle';
import Widget from '../../../components/Widget/Widget';
import Table from '../../../components/Table/CategoriesTable';
import {
  fetchCategories,
  fetchServices,
  fetchProviders,
  API_BASE,
  deleteProvider,
} from '../../../helpers/api';
import CategoryCreator from '../CategoryCreator';
import ServiceCreator from '../ServiceCreator';
import { ToastContainer } from 'react-toastify';
import ProviderCreator from '../ProviderCreator';
import axios from 'axios';
import { useContext } from 'react';
import { NotificationContext } from '../../../components/Layout/notificationContext';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(3),
  },
}));

export default function Providers() {
  const [providers, setProviders] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [phone, setPhone] = useState('');
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [render, setRender] = useState(false);
  const [id, setId] = useState('');

  useEffect(() => {
    fetchData();
    getProviders();
    getCategories();
  }, []);

  useEffect(() => {
    console.log('I re-rendered');
  });

  async function fetchData() {
    var newServices = [];
    try {
      newServices = await fetchServices();
      setAllServices(newServices);
      console.log(newServices);
    } catch (err) {
      console.log(err);
    }
    setAllServices(newServices);
  }

  const getCategories = async () => {
    try {
      const categories = await fetchCategories();
      setCategories(categories);
    } catch (err) {
      console.log(err);
    }
  };

  async function getProviders() {
    try {
      const rawProviders = await fetchProviders(true);
      var parsedProviders = [];
      for (var i = 0; i < rawProviders.length; ++i) {
        const item = rawProviders[i];
        console.log(item);
        parsedProviders.push([
          item.name,
          item.services,
          item._id,
          item.rawServices,
          item.phone || '',
        ]);
      }
      setProviders(parsedProviders);
    } catch (err) {
      console.log(err);
    }
  }

  const notification = useContext(NotificationContext);

  const handleEditClose = () => {
    setEditOpen(false);
    setName('');
    setServices([]);
    setPhone('');
    setId('');
  };

  async function handleProviderDelete(index) {
    var deletedIndexes = [];
    index.forEach((item) => {
      deletedIndexes.push(item.dataIndex);
    });
    for (var i = 0; i < deletedIndexes.length; ++i) {
      try {
        const response = await deleteProvider(providers[deletedIndexes[i]][2]);
        notification.current.sendToast(
          'SUCCESS',
          'Provider Deleted successfully'
        );
        console.log(response);
      } catch (err) {
        console.log(err);
      }
    }
    console.log(deletedIndexes);
  }

  const providerEditor = () => {
    //console.log(services);
  };

  return (
    <>
      <ProviderEditor
        categories={categories}
        id={id}
        providers={providers}
        editOpen={editOpen}
        allServices={allServices}
        onClose={handleEditClose}
        getProviders={getProviders}
      />
      <PageTitle title="Providers Manager" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title="Add new provider" upperTitle disableWidgetMenu>
            <ProviderCreator onCreateNew={() => getProviders()} />
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <MUIDataTable
            title="Providers List"
            data={providers}
            columns={['Name', 'Services', 'Provider ID']}
            options={{
              filterType: 'checkbox',
              onRowsDelete: (item) => handleProviderDelete(item.data),
              onRowClick: (_, index) => {
                setId(providers[index.dataIndex][2]);
                setServices(providers[index.dataIndex][3]);
                setPhone(providers[index.dataIndex][4]);
                setName(providers[index.dataIndex][0]);
                setEditOpen(true);
              },
            }}
          />
        </Grid>
      </Grid>
      {providerEditor()}
    </>
  );
}

const ProviderEditor = ({
  categories,
  id,
  providers,
  editOpen,
  allServices,
  onClose,
  getProviders,
}) => {
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [phone, setPhone] = useState('');
  const [services, setServices] = useState([]);
  const [servicesOpen, setServicesOpen] = useState(false);
  const [render, setRender] = useState(false);

  const dialogStyles = useStyles();

  useEffect(() => {
    const provider = providers[providers.findIndex((item) => item[2] == id)];
    console.log(provider, providers);
    if (provider) {
      setName(provider[0]);
      setPhone(provider[4] ? provider[4] : '');
      setServices(provider[3]);
    }
    console.log('I changed my ID');
  }, [id]);

  useEffect(() => {
    if (!category) return;
    let filteredServices = allServices.filter(
      (item) => item.rawCategory == category
    );
    filteredServices = filteredServices.concat(
      allServices.filter((item) => services.includes(item.id))
    );
    filteredServices = Array.from(new Set(filteredServices));
    let array = [];
    for (let i = 0; i < filteredServices.length; i++) {
      array.push(filteredServices[i].id);
    }
    setServices(array);
  }, [category]);

  const notification = useContext(NotificationContext);

  const handleEditConfirm = async () => {
    try {
      let newServices = Array.from(new Set(services));
      newServices = newServices.filter((item) => item.length > 2);
      const response = await axios.put(`${API_BASE}/provider/update/${id}`, {
        name,
        services: newServices,
        phone,
      });
      console.log(response);
      notification.current.sendToast('SUCCESS', 'Updated provider info');
      onClose();
      getProviders();
    } catch (err) {
      console.log(err);
    }
  };
  const handleAddService = () => {
    const newService = '';
    var servicesO = services;
    servicesO.push(newService);
    console.log(services);
    setServices(servicesO);
    setRender(!render);
  };

  return (
    <Dialog
      open={editOpen}
      onClose={() => onClose()}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Update provider</DialogTitle>
      <div className={dialogStyles.dialog}>
        <DialogContentText>
          Set provider new name in the field below. {'\n'}To remove the service,
          just select the empty option
        </DialogContentText>
        <TextField
          value={name}
          id="standard-basic"
          label="Name"
          onChange={(e) => setName(e.target.value)}
          margin="normal"
          placeholder="e.g. Laser Upperbody"
          type="text"
          fullWidth
        />
        <TextField
          value={phone}
          id="standard-basic"
          label="Phone"
          onChange={(e) => setPhone(e.target.value)}
          margin="normal"
          placeholder="+4411122223333"
          type="tel"
          fullWidth
        />
      </div>
      <Button
        onClick={() => setServicesOpen((prevState) => !prevState)}
        color={servicesOpen ? 'secondary' : 'primary'}
        style={{ marginTop: 20, marginLeft: 15, marginRight: 15 }}
        variant="contained"
        margin="normal"
      >
        {servicesOpen ? 'Hide Service Editor' : 'Show Service Editor'}
      </Button>
      {servicesOpen && (
        <>
          <Button
            onClick={() => handleAddService()}
            color="primary"
            style={{ marginTop: 20, marginLeft: 15, marginRight: 15 }}
            variant="contained"
            margin="normal"
          >
            Add Service
          </Button>

          {services.map((service, index) => {
            //console.log(service);
            return (
              <FormControl margin="normal" key={service._id}>
                <InputLabel
                  htmlFor="age-native-simple"
                  style={{ marginLeft: 15, marginRight: 15 }}
                >
                  Service {index + 1}
                </InputLabel>
                <Select
                  native
                  value={service}
                  key={service}
                  style={{ marginLeft: 15, marginRight: 15 }}
                  onChange={(e) => {
                    console.log(e.target.value);
                    var servicesO = services;
                    servicesO[index] = e.target.value;
                    console.log(servicesO);
                    setServices(servicesO);
                    setRender(!render);
                    // setServices(e.target.value);
                  }}
                  inputProps={{
                    name: 'age',
                    id: 'age-native-simple',
                  }}
                >
                  <option value=""></option>
                  {allServices.map((serviceOptions) => {
                    return (
                      <option key={serviceOptions.id} value={serviceOptions.id}>
                        {serviceOptions.name}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            );
          })}
          <Typography margin="normal">Fill services with category</Typography>
          <FormControl margin="normal">
            <InputLabel htmlFor="age-native-simple">
              Fill with category
            </InputLabel>
            <Select
              native
              value={category}
              key={category}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
              inputProps={{
                name: 'category',
                id: 'category-selector',
              }}
              //fullWidth
            >
              <option value=""></option>
              {categories.map((category) => {
                return (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </>
      )}
      <DialogActions>
        <Button
          onClick={() => {
            setCategory('');
            setServicesOpen(false);
            onClose();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleEditConfirm}
          disabled={
            !phone.match(
              /^(((\+44\s?\d{4})\s?\d{3}\s?\d{3})|((\+44\s?\d{3})\s?\d{3}\s?\d{4})|((\+44\s?\d{2})\s?\d{4}\s?\d{4}))?$/
            )
          }
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
