import moment from 'moment';

function isContained(slot, timeSlot) {
  const time = slot.slot_time;

  return (
    (timeSlot[0] > time[0] && timeSlot[0] < time[1]) ||
    (timeSlot[1] > time[0] && timeSlot[1] < time[1]) ||
    (timeSlot[0] == time[0] && timeSlot[1] == time[1]) ||
    timeSlot[0] == time[0] ||
    timeSlot[1] == time[1]
  );
}

function isContainedV2(t1, t2, slots, date) {
  if (!slots.length) return false;

  let timeSet = new Set();

  for (let i = 0; i < slots.length; ++i) {
    [...Array(parseInt(slots[i].slot_time[1] - slots[i].slot_time[0])).keys()]
      .map((item) => item + slots[i].slot_time[0])
      .forEach((item) => timeSet.add(item));
  }

  const uniqueSlots = Array.from(timeSet);

  const timesBetween = [...Array(t2 - t1).keys()].map((item) => item + t1);

  if (timesBetween.some((item) => uniqueSlots.includes(item))) {
    return true;
  }

  return false;
}

export function buildTimeStringsArray(
  date,
  occupiedSlots = [],
  serviceDuration,
  provider,
  currentTime = ''
) {
  let _slots = provider
    ? provider.days[moment(date).isoWeekday() - 1].slots
    : [
        [12, 30],
        [36, 54],
      ];

  var stringArray = [];
  for (const slots of _slots) {
    if (date && occupiedSlots.length && serviceDuration) {
      // console.log(occupiedSlots);
      for (var i = slots[0]; i < slots[1]; ++i) {
        if (
          isContainedV2(
            i,
            i + serviceDuration,
            occupiedSlots.filter(
              (item) =>
                item.slot_date == moment(date).format('YYYY-MM-DD').toString()
            ),
            date
          )
        )
          continue;

        // if (notValid) continue;

        const hour = Math.floor((i * 15) / 60);
        const hour1 = Math.floor(((i + serviceDuration) * 15) / 60);
        const minutes = i * 15 - Math.floor((i * 15) / 60) * 60;
        const minutes1 =
          (i + serviceDuration) * 15 -
          Math.floor(((i + serviceDuration) * 15) / 60) * 60;

        const timeString =
          `${hour}`.padStart(2, 0) +
          ':' +
          `${minutes}`.padStart(2, 0) +
          ' - ' +
          `${hour1}`.padStart(2, 0) +
          ':' +
          `${minutes1}`.padStart(2, 0);

        stringArray.push(timeString);
      }
    } else {
      for (var i = slots[0]; i < slots[1]; ++i) {
        const hour = Math.floor((i * 15) / 60);
        const hour1 = Math.floor(((i + serviceDuration) * 15) / 60);

        const minutes = i * 15 - Math.floor((i * 15) / 60) * 60;
        const minutes1 =
          (i + serviceDuration) * 15 -
          Math.floor(((i + serviceDuration) * 15) / 60) * 60;

        const timeString =
          `${hour}`.padStart(2, 0) +
          ':' +
          `${minutes}`.padStart(2, 0) +
          ' - ' +
          `${hour1}`.padStart(2, 0) +
          ':' +
          `${minutes1}`.padStart(2, 0);

        stringArray.push(timeString);
      }
    }
  }
  if (currentTime) stringArray.push(currentTime);
  return stringArray;
}

export function slotTimeToString(slot) {
  const hour = Math.floor((slot * 15) / 60);
  const minutes = slot * 15 - Math.floor((slot * 15) / 60) * 60;
  const timeString =
    `${hour}`.padStart(2, 0) + ':' + `${minutes}`.padStart(2, 0);
  return timeString;
}

export function timeStringToSlotNumber(string, step = 0) {
  const [h, m] = string.split(':');
  const slot = h * 4 + m / 15 + step;
  return slot;
}

export function addTime(initial, amount) {
  var slotValue = timeStringToSlotNumber(initial);
  slotValue += amount;
  const timeString = slotTimeToString(slotValue);
  return timeString;
}
