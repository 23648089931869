import React, { useState, useEffect, useContext } from 'react';
import { API_BASE, fetchAppointment } from '../../../helpers/api';
import moment from 'moment';
import {
  Paper,
  CircularProgress,
  Divider,
  Button,
  Dialog,
  Typography,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useMemo } from 'react';
import { useUserState } from '../../../helpers/UserContext';
import Axios from 'axios';
import { NotificationContext } from '../../../components/Layout/notificationContext';

export default function AppointmentDetail(props) {
  const [appointment, setAppointment] = useState({});
  const [loading, setLoading] = useState(true);
  const [cancelConfirmation, setCancelConfirmation] = useState(false);

  const { user } = useUserState();

  useMemo(getAppointment, [props.appointmentToLoad]);

  // useEffect(() => {
  //   getAppointment();
  // }, []);

  const handleAppointmentEdit = () => {
    if (props.handleEditAppointment)
      props.handleEditAppointment.select(appointment);
  };

  async function getAppointment() {
    const id = props.appointmentToLoad;
    try {
      const newAppointment = await fetchAppointment(id);
      if (newAppointment != appointment) setAppointment(newAppointment);
      if (setLoading) setLoading(false);
      return newAppointment;
    } catch (err) {
      console.log(err);
    }
  }

  const notification = useContext(NotificationContext);

  async function handleAppointmentCancel() {
    try {
      const request = await Axios.put(
        `${API_BASE}/appointment/cancel/${props.appointmentToLoad}`
      );
      notification.current.sendToast(
        'SUCCESS',
        'Service canceled successfully'
      );
      setCancelConfirmation(false);
      console.log(request.data.message);
    } catch (err) {
      console.log(err);
    }
  }

  const spanStyle = { color: '#00C853' };

  return (
    <React.Fragment>
      {loading ? (
        <Paper
          style={{
            display: 'flex',
            flex: 1,
            height: 200,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className="spinnerContainer"
        >
          <CircularProgress />
        </Paper>
      ) : (
        <div style={{ display: 'flex' }}>
          {user.role == 2 && (
            <Dialog open={cancelConfirmation}>
              <DialogTitle id="form-dialog-title">
                Are you sure you want to cancel this appointment?
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  This appointment has already been payed, cancelling the
                  appointment can not be undone and the time slot will be freed
                  to be booked again
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleAppointmentCancel}
                >
                  Yes
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => setCancelConfirmation(false)}
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>
          )}
          <div style={{ marginRight: 10 }}>
            <section>
              <p>
                Name: <span style={spanStyle}>{appointment.name}</span>
              </p>
              <p>
                Number: <span style={spanStyle}>{appointment.phone}</span>
              </p>
              <p>
                Email: <span style={spanStyle}>{appointment.email}</span>
              </p>
              <p>
                Appointment:{' '}
                <span style={spanStyle}>
                  {moment(appointment.slot_id.slot_date).format(
                    'dddd[,] MMMM Do[,] YYYY'
                  )}
                </span>{' '}
                from{' '}
                <span style={spanStyle}>
                  {slotTimeToString(appointment.slots[0])}
                </span>{' '}
                to{' '}
                <span style={spanStyle}>
                  {slotTimeToString(appointment.slots[1])}
                </span>
              </p>
              <p>
                Service:{' '}
                <span style={spanStyle}>{appointment.service.name}</span>
              </p>
              <p>
                Provider:{' '}
                <span style={spanStyle}>{appointment.provider.name}</span>
              </p>
            </section>
          </div>
          <Divider orientation="vertical" flexItem />
          <div style={{ marginLeft: 10 }}>
            <p>
              Price: <span style={spanStyle}>£{appointment.service.price}</span>
            </p>
            <p>
              Duration:{' '}
              <span style={spanStyle}>
                {appointment.service.timeInterval * 15} min
              </span>
            </p>
            {appointment.canceled && (
              <span style={{ color: '#f00' }}>CANCELED</span>
            )}
            {!appointment.canceled && (
              <>
                {user.role === 2 && (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleAppointmentEdit}
                  >
                    Edit appointment
                  </Button>
                )}
                <Button
                  color="secondary"
                  onClick={() => setCancelConfirmation(true)}
                >
                  Cancel Appointment
                </Button>
              </>
            )}
            <Button
              color="primary"
              variant="contained"
              onClick={() => props.scheduleReturn(appointment)}
            >
              Schedule Return
            </Button>
          </div>
          <Button
            style={{ height: 40, width: 40, marginLeft: 20 }}
            onClick={props.closeDetail}
          >
            <Close />
          </Button>
        </div>
      )}
    </React.Fragment>
  );
}

function slotTimeToString(slot) {
  const hour = Math.floor(slot / 4);
  const minutes = (slot % 4) * 15;
  const timeString =
    `${hour}`.padStart(2, 0) + ':' + `${minutes}`.padStart(2, 0);
  return timeString;
}
