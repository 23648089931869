import React, { useState } from 'react';
import {
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
} from '@material-ui/core';
import classnames from 'classnames';

// styles
import useStyles from './styles';

// logo
import logo from './logo.svg';
import google from '../../images/google.svg';

// context
import { registerUser, useUserDispatch } from '../../helpers/UserContext';
//import { useUserDispatch, loginUser } from '../../context/UserContext';

const SignUpComponent = (props) => {
  var classes = useStyles();

  const userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [nameValue, setNameValue] = useState('');
  var [loginValue, setLoginValue] = useState('');
  var [passwordValue, setPasswordValue] = useState('');
  var [phoneValue, setPhoneValue] = useState('');
  var [providerValue, setProviderValue] = useState('');

  return (
    <React.Fragment>
      <Typography variant="h1" className={classes.greeting}>
        Welcome!
      </Typography>
      <Typography variant="h2" className={classes.subGreeting}>
        Create your account
      </Typography>
      <Fade in={error}>
        <Typography color="secondary" className={classes.errorMessage}>
          Something is wrong with your login or password :(
        </Typography>
      </Fade>
      {/* <TextField
        id="name"
        InputProps={{
          classes: {
            underline: classes.textFieldUnderline,
            input: classes.textField,
          },
        }}
        value={nameValue}
        onChange={(e) => {
          setNameValue(e.target.value);
          props.editHandler('nameValue', e);
        }}
        margin="normal"
        placeholder="Full Name"
        type="text"
        fullWidth
      /> */}
      <TextField
        id="email"
        InputProps={{
          classes: {
            underline: classes.textFieldUnderline,
            input: classes.textField,
          },
        }}
        value={loginValue}
        onChange={(e) => {
          setLoginValue(e.target.value);
          props.editHandler('loginValue', e);
        }}
        margin="normal"
        placeholder="Email Adress"
        type="email"
        fullWidth
      />
      <TextField
        id="password"
        InputProps={{
          classes: {
            underline: classes.textFieldUnderline,
            input: classes.textField,
          },
        }}
        value={passwordValue}
        onChange={(e) => {
          setPasswordValue(e.target.value);
          props.editHandler('passwordValue', e);
        }}
        margin="normal"
        placeholder="Password"
        type="password"
        fullWidth
      />
      <TextField
        id="Provider ID"
        InputProps={{
          classes: {
            underline: classes.textFieldUnderline,
            input: classes.textField,
          },
        }}
        value={providerValue}
        onChange={(e) => {
          setProviderValue(e.target.value);
          props.editHandler('providerValue', e);
        }}
        margin="normal"
        placeholder="Provider ID"
        type="text"
        fullWidth
      />
      {/* <TextField
        id="Phone Number"
        InputProps={{
          classes: {
            underline: classes.textFieldUnderline,
            input: classes.textField,
          },
        }}
        value={phoneValue}
        onChange={(e) => {
          setPhoneValue(e.target.value);
          props.editHandler('phoneValue', e);
        }}
        margin="normal"
        placeholder="Phone Number"
        type="number"
        fullWidth
      /> */}
      <div className={classes.creatingButtonContainer}>
        {isLoading ? (
          <CircularProgress size={26} />
        ) : (
          <Button
            onClick={() =>
              registerUser(
                userDispatch,
                loginValue,
                passwordValue,
                props.history,
                setIsLoading,
                setError,
                providerValue
                //phoneValue
              )
            }
            disabled={loginValue.length === 0 || passwordValue.length === 0}
            size="large"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.createAccountButton}
          >
            Create your account
          </Button>
        )}
      </div>
      {/* <div className={classes.formDividerContainer}>
        <div className={classes.formDivider} />
        <Typography className={classes.formDividerWord}>or</Typography>
        <div className={classes.formDivider} />
      </div>
      <Button
        size="large"
        className={classnames(
          classes.googleButton,
          classes.googleButtonCreating
        )}
      >
        <img src={google} alt="google" className={classes.googleIcon} />
        &nbsp;Sign in with Google
      </Button> */}
    </React.Fragment>
  );
};

export default SignUpComponent;
