import React, { useState } from 'react';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button as Btn,
  Dialog,
  InputLabel,
  Select,
  Icon,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
// components
import { Button } from '../Wrappers/Wrappers';
import axios from 'axios';
import { getThemeProps } from '@material-ui/styles';
import { toast, ToastContainer } from 'react-toastify';
import Notification from '../Notification/Notification';
import { useToastStyles } from '../../pages/dashboard/toastStyles/toastStyles';
import { API_BASE } from '../../helpers/api';

const states = {
  sent: 'success',
  pending: 'warning',
  declined: 'secondary',
};

const days = [
  'Name',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const useStyles = makeStyles((theme) => ({
  timeLabel: {
    backgroundColor: '#3FC7F3',
    padding: 5,
    borderRadius: 5,
    marginBottom: 5,
  },
  inputHolder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginTop: 10,
  },
  distribute: {
    width: '50%',
  },
  root: {
    display: 'flex',
    marginTop: theme.spacing(3),
    overflowX: 'hide',
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
  row: {
    display: 'inline-block',
  },
}));

export default function ProvidersTimeTable({ data, ...props }) {
  // var keys = Object.keys(data[0]).map((i) => i.toUpperCase());
  // keys.shift(); // delete "id" key

  const classes = useStyles();
  const toastStyles = useToastStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState();
  const [index, setIndex] = useState();
  const [name, setName] = useState('Henrique');
  const [time, setTime] = useState([
    ['09:00', '12:00'],
    ['13:00', '17:00'],
  ]);
  const [provider, setProvider] = useState();
  const [timeInSlot, setTimeInSlot] = useState([0, 0]);
  const [day, setDay] = useState('Monday');

  const handleAddShift = () => {
    if (time.length == 1) {
      var newTime = [];
      newTime.push(time[0]);
      newTime.push([addTime(time[0][1], 4), addTime(time[0][1], 20)]);
      setTime(newTime);
    } else if (time.length == 0) {
      var newTime = [];
      newTime.push(['09:00', '12:00']);
      setTime(newTime);
    }
  };

  const handleDeleteShift = () => {
    if (time.length == 2) {
      var newTime = [];
      newTime.push(time[0]);
      setTime(newTime);
    }
    if (time.length == 1) setTime([]);
  };

  const updateShiftOnDB = async () => {
    setLoading(true);
    const id = provider._id;
    var data = provider.data;
    var newArr = [];
    for (var i = 0; i < time.length; ++i) {
      newArr.push([
        timeStringToSlotNumber(time[i][0]),
        timeStringToSlotNumber(time[i][1]),
      ]);
    }
    data[index].slots = newArr;
    console.log(data);
    const response = await axios.put(`${API_BASE}/provider/updateShift/${id}`, {
      days: data,
    });
    setLoading(false);
    props.refreshSchedule();
    console.log(response);
  };

  const timeLabelAndSelector = (timeToDisplay, index) => {
    console.log(time);
    return (
      <div className={classes.inputHolder}>
        <div className={classes.distribute}>
          <InputLabel htmlFor="age-native-simple">SHIFT START</InputLabel>
          <Select
            native
            value={timeToDisplay[0]}
            // key={service}
            onChange={(e) => {
              console.log(e.target.value);
              var newTime = [];
              if (index == 0) {
                newTime.push([e.target.value, time[0][1]]);
                if (time.length > 1) newTime.push(time[1]);
              } else {
                newTime.push(time[0]);
                newTime.push([e.target.value, time[1][1]]);
              }
              setTime(newTime);
              var slot = timeInSlot;
              slot[0] = timeStringToSlotNumber(e.target.value);
              setTimeInSlot(slot);
            }}
            style={{ width: '90%', marginRight: 10 }}
          >
            {buildTimeStringsArray().map((time) => {
              return <option key={time}>{time}</option>;
            })}
          </Select>
        </div>
        <div className={classes.distribute}>
          <InputLabel htmlFor="age-native-simple">SHIFT END</InputLabel>
          <Select
            native
            value={timeToDisplay[1]}
            // key={service}
            onChange={(e) => {
              console.log(e.target.value);
              var newTime = [];
              if (index == 0) {
                newTime.push([time[0][0], e.target.value]);
                if (time.length > 1) newTime.push(time[1]);
              } else {
                newTime.push(time[0]);
                newTime.push([time[1][0], e.target.value]);
              }
              // newTime.push(e.target.value);
              setTime(newTime);
              var slot = timeInSlot;
              slot[1] = timeStringToSlotNumber(e.target.value);
              setTimeInSlot(slot);
            }}
            style={{ width: '90%' }}
          >
            {buildTimeStringsArray().map((time) => {
              return <option key={time}>{time}</option>;
            })}
          </Select>
        </div>
        {(index == 1 || index == 0) && (
          <Btn onClick={handleDeleteShift}>
            <Icon>close</Icon>
          </Btn>
        )}
      </div>
    );
  };

  const renderShiftUpdateModal = () => {
    const spanStyle = { color: '#00C853' };
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Update <span style={spanStyle}>{name}</span>'s shift
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update <span style={spanStyle}>{name}</span>'s new shift for{' '}
            <span style={spanStyle}>{day}</span>, you can add one or two shifts
            in a day
          </DialogContentText>
          {time.map((timeToRender, index) => {
            return timeLabelAndSelector(timeToRender, index);
          })}
          {time.length < 2 && (
            <Btn
              variant="outlined"
              style={{ marginTop: 10 }}
              onClick={handleAddShift}
            >
              Add another shift
            </Btn>
          )}
        </DialogContent>
        <DialogActions>
          <Btn
            loading={loading}
            disabled={loading}
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Btn>
          <Btn
            loading={loading}
            disabled={loading}
            onClick={handleConfirm}
            color="primary"
          >
            Confirm
          </Btn>
        </DialogActions>
      </Dialog>
    );
  };

  const isValidShift = () => {
    var isValid = true;
    if (time.length == 2)
      isValid = time[1][0] > time[0][1] && time[1][1] > time[1][0];
    if (time.length > 0) isValid = time[0][1] > time[0][0] && isValid == true;
    return isValid;
  };

  const sendToast = (type) => {
    const componentProps = {
      type: 'shipped',
      message: `Successfully updated provider`,
      variant: 'contained',
      color: 'success',
    };
    const options = {
      type: 'success',
      position: toast.POSITION.TOP_RIGHT,
      progressClassName: toastStyles.progress,
      // onClose: notificationType === 'error' && (() => setErrorToastId(null)),
      className: toastStyles.notification,
    };
    switch (type) {
      case 'SUCCESS':
        toast(
          <Notification
            {...componentProps}
            className={toastStyles.notificationComponent}
          />,
          {
            type: 'success',
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: toastStyles.progress,
            className: toastStyles.notification,
          }
        );
        break;
      case 'ERROR':
        toast.error('There was an error during the update!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    }
  };

  const handleConfirm = async () => {
    console.log(isValidShift());
    if (isValidShift()) {
      await updateShiftOnDB();
      console.log('shift change confirmed');
      sendToast('SUCCESS');
      setOpen(false);
    }
  };

  const handleClickOpen = (item, day, index) => {
    console.log(item, day, index);
    var slots = [];
    for (var i = 0; i < day.slots.length; ++i) {
      slots.push([
        slotTimeToString(day.slots[i][0]),
        slotTimeToString(day.slots[i][1]),
      ]);
    }
    setTime(slots);
    setDay(day.day);
    setName(item.name);
    setProvider(item);
    setIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {days.map((key) => (
              <TableCell key={key} align="center">
                {key}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, oneIndex) => (
            <TableRow key={oneIndex}>
              <TableCell>{item.name}</TableCell>
              {item.data.map((day, index) => {
                return (
                  <TableCell
                    align="center"
                    onClick={() => handleClickOpen(item, day, index)}
                  >
                    {day.slots.map((slot) => {
                      return (
                        <div className={classes.timeLabel}>
                          <span>{slotTimeToString(slot[0])} – </span>
                          <span>{slotTimeToString(slot[1])}</span>
                        </div>
                      );
                    })}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {renderShiftUpdateModal()}
    </div>
  );
}

function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}

function buildTimeStringsArray() {
  var slots = [0, 96];
  var stringArray = [];
  for (var i = 0; i < slots[1]; ++i) {
    const hour = Math.floor((i * 15) / 60);
    const minutes = i * 15 - Math.floor((i * 15) / 60) * 60;
    const timeString =
      `${hour}`.padStart(2, 0) + ':' + `${minutes}`.padStart(2, 0);
    stringArray.push(timeString);
  }
  return stringArray;
}

function slotTimeToString(slot) {
  const hour = Math.floor((slot * 15) / 60);
  const minutes = slot * 15 - Math.floor((slot * 15) / 60) * 60;
  const timeString =
    `${hour}`.padStart(2, 0) + ':' + `${minutes}`.padStart(2, 0);
  return timeString;
}

function timeStringToSlotNumber(string, step = 0) {
  const [h, m] = string.split(':');
  const slot = h * 4 + m / 15 + step;
  return slot;
}

function addTime(initial, amount) {
  var slotValue = timeStringToSlotNumber(initial);
  slotValue += amount;
  const timeString = slotTimeToString(slotValue);
  return timeString;
}
