import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  CircularProgress,
  InputLabel,
  Select,
  InputAdornment,
  FormControl,
} from '@material-ui/core';
import {
  fetchServices,
  createProvider,
  createNotificationModels,
} from '../../../helpers/api';
import { createSale } from '../../../helpers/api';

// import { Container } from './styles';

function NotificationModelCreator(props) {
  const [headline, setHeadline] = useState('');
  const [body, setBody] = useState('');
  const [loading, setLoading] = useState(false);

  const handleNotificationModelCreation = async () => {
    setLoading(true);
    if (!checkValid) return;
    try {
      const response = await createNotificationModels(headline, body);
      console.log(response);
      props.onCreateNew();
      setHeadline([]);
      setBody('');
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const checkValid = () => {
    return headline.length > 2 && body.length > 2;
  };

  return (
    <React.Fragment>
      <div className="fill-width">
        <TextField
          value={headline}
          id="standard-basic"
          label="Headline"
          //error={!(amount > 0 && amount <= 100)}
          onChange={(e) => setHeadline(e.target.value)}
          margin="normal"
          helperText={
            headline.length < 3
              ? 'Headline should be at least 3 characters long'
              : ''
          }
          placeholder="e.g. New Offer Online"
          type="text"
          fullWidth
          style={{ marginRight: 10 }}
        />
        <TextField
          value={body}
          id="standard-basic"
          label="Body"
          multiline={true}
          //error={code.length < 3}
          onChange={(e) => setBody(e.target.value)}
          margin="normal"
          helperText={
            body.length < 3 ? 'Body should be at least 3 characters long' : ''
          }
          placeholder="e.g. Check the new discounts in laser waxing! Online for limited time!"
          type="text"
          variant="outlined"
          fullWidth
          style={{ marginRight: 10 }}
        />
        {loading ? (
          <CircularProgress size={26} />
        ) : (
          <Button
            color="primary"
            style={{ marginTop: 20 }}
            variant="contained"
            margin="normal"
            fullWidth
            onClick={async () => await handleNotificationModelCreation()}
            disabled={loading || headline.length < 3 || body.length === 0}
          >
            Create
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}

export default NotificationModelCreator;
