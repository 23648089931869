import { makeStyles, Select, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { API_BASE, fetchProviders } from '../../../helpers/api';
import axios from 'axios';

// import { Container } from './styles';

const useStyles = makeStyles((theme) => ({
  box: {
    width: 200,
    backgroundColor: '#18e7ad',
    borderRadius: '1rem',
    padding: 10,
    margin: 10,
  },
  flex: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  value: {},
  onMonth: {},
}));

function PurchaseLister(props) {
  const [balance, setBalance] = useState({
    all: 0,
    onMonth: 0,
    customDays: 0,
    provider: 0,
  });
  const [timeAmount, setTimeAmount] = useState(
    props.timeAmount ? props.timeAmount : 0
  );
  const [provider, setProvider] = useState('');
  const [providers, setProviders] = useState([]);
  const isFirstRender = useRef(true);
  const timeout = useRef(null);

  useEffect(() => {
    getBalance();
    if (props.provider) getProviders();
  }, []);

  useEffect(() => {
    if (!isFirstRender.current) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        getCustomDateBalance();
      }, 700);
    }
  }, [timeAmount]);

  useEffect(() => {
    if (props.provider) {
      getProviderBalance();
    }
  }, [provider]);

  async function getBalance() {
    try {
      const allTime = await axios.get(`${API_BASE}/accounting/balance`);
      if (timeAmount) {
        const customTime = await axios.get(
          `${API_BASE}/accounting/balance/${timeAmount}`
        );
        setBalance({
          ...balance,
          customDays: customTime.data.balance,
          all: allTime.data.balance,
        });
        isFirstRender.current = false;
      } else {
        const onMonth = await axios.get(`${API_BASE}/accounting/onMonth`);
        setBalance({
          ...balance,
          all: allTime.data.balance,
          onMonth: onMonth.data.onMonth,
        });
        isFirstRender.current = false;
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function getCustomDateBalance() {
    try {
      const customTime = await axios.get(
        `${API_BASE}/accounting/balance/${timeAmount}`
      );
      setBalance({
        ...balance,
        customDays: customTime.data.balance,
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function getProviderBalance() {
    if (!provider || !props.provider) return;
    const providerBalance = await axios.get(
      `${API_BASE}/accounting/byProvider/${provider}`
    );
    setBalance({ ...balance, provider: providerBalance.data.balance });
  }

  async function getProviders() {
    try {
      const providers = await fetchProviders();
      setProviders(providers);
    } catch (err) {
      console.log(err);
    }
  }

  const handleTimeSelect = (e) => {
    if (e.target.value <= 90) setTimeAmount(e.target.value);
  };

  const classes = useStyles();
  return (
    <div>
      <div className={classes.box}>
        {props.provider && (
          <Select native onChange={(e) => setProvider(e.target.value)}>
            {providers.map((provider) => (
              <option value={provider._id}>{provider.name}</option>
            ))}
          </Select>
        )}
        {props.timeAmount && (
          <div className={classes.flex}>
            <Typography
              style={{
                color: '#fff',
                fontSize: 18,
                fontWeight: 'bold',
                textAlign: 'center',
                marginRight: 5,
              }}
            >
              Days:
            </Typography>
            <TextField value={timeAmount} onChange={handleTimeSelect} />
          </div>
        )}
        <Typography
          style={{
            color: '#fff',
            fontSize: 20,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {timeAmount
            ? `On last ${timeAmount} days`
            : props.provider
            ? `${
                provider &&
                providers[providers.findIndex((item) => item._id == provider)]
                  .name
              } on last 30 days`
            : 'All-time sales'}
        </Typography>

        <Typography
          className={classes.value}
          variant="h4"
          style={{
            color: '#fff',
            fontSize: 22,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          £{' '}
          {timeAmount
            ? balance.customDays.toFixed(2)
            : props.provider
            ? balance.provider.toFixed(2)
            : balance.all.toFixed(2)}
        </Typography>
        {!props.timeAmount && !props.provider && (
          <Typography
            className={classes.onMonth}
            style={{
              color: '#fff',
              fontSize: 14,
              fontWeight: 'bold',
              textAlign: 'center',
              flex: 1,
            }}
          >
            {balance.onMonth >= 0 ? '+' : '-'} £ {balance.onMonth.toFixed(2)} on
            this month
          </Typography>
        )}
      </div>
    </div>
  );
}

export default PurchaseLister;
